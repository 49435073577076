import {API, Logger} from "aws-amplify";
import {FormikHelpers, FormikValues} from "formik";
import React from "react";
import {Card} from "react-bootstrap";
import {v4 as uuidv4} from "uuid";
import * as yup from "yup";
import {DataAudit} from "../../CommonApi/DataAudit";
import {EventType, FrontendModule} from "../../CommonApi/Enum";
import {UsageTracking} from "../../CommonApi/UsageTracking";
import {ApiCallStatus} from "../Common/Enum";
import {DataImportFormSubmissionStatus, HtmlForm, RowOfFormFields} from "../Common/HtmlForm";
import {View} from "../GenGenPlate/Enum";
import {gppApiConsts} from "./GppApiConsts";

const logger = new Logger("DatabasesImportAddNewIndel.tsx")
const TABLE_NAME: string = "indel_performance"

export interface Indel {
    id: string,
    gene: string
    plasmidId: string,
    plateId: string,
    cellLine: string,
    guideSequence: string,
    method: string,
    indelPercentage: string,
    modelFit: string,
    knockoutScore: string,
    collectionTimepoint: string,
    cellSelectionMarker: string,
    deliveryMethod: string,
    owner: string,
    project: string,
    additionalNote: string,
    lastModified: string,
    forwardPrimerSequence:  string,
    reversePrimerSequence:  string,
    annealingTemp:          string,
    additive:               string,
    polymerase:             string,
    ampliconSizeInBp:       string,
    sangerSequencingPrimer: string,
    wildtypeControlFile:    string,
}

interface IndelFormState {
    rowsOfFormFields: RowOfFormFields[]
    schema: any
    initialValues: Indel
    submissionStatus: DataImportFormSubmissionStatus
}

interface IndelFormProps {
    displayHeader: boolean
    initialValues: Indel
    autofillOwnerByLogInId: boolean
    view: View
}

export class IndelForm extends React.Component<IndelFormProps, IndelFormState> {
    static defaultProps = {
        displayHeader: true,
        initialValues: null,
        autofillOwnerByLogInId: true,
    }

    constructor(props: IndelFormProps) {
        super(props);
        this.state = {
            rowsOfFormFields: [
                {
                    colSize: 3,
                    fields: [
                        {name: "plasmidId", dataType: "text", disable: false},
                        {name: "gene", dataType: "text", disable: false},
                        {name: "cellLine", dataType: "text", disable: false},
                        {name: "collectionTimepoint", dataType: "number", disable: false},
                        {name: "indelPercentage", dataType: "number", disable: false},
                        {name: "modelFit", dataType: "number", disable: false},
                        {name: "knockoutScore", dataType: "number", disable: false},
                        {name: "cellSelectionMarker", dataType: "select", disable: false},
                        {name: "method", dataType: "select", disable: false},
                        {name: "deliveryMethod", dataType: "select", disable: false},
                        {name: "project", dataType: "text", disable: false},
                        {name: "annealingTemp", dataType: "number", disable: false},
                        {name: "additive", dataType: "select", disable: false},
                        {name: "polymerase", dataType: "select", disable: false},
                        {name: "ampliconSizeInBp", dataType: "number", disable: false},
                    ],
                },
                {
                    colSize: 6,
                    fields: [
                        {name: "plateId", dataType: "text", disable: false},
                        {name: "guideSequence", dataType: "text", disable: false},
                        {name: "forwardPrimerSequence", dataType: "text", disable: false},
                        {name: "reversePrimerSequence", dataType: "text", disable: false},
                        {name: "owner", dataType: "text", disable: true},
                        {name: "sangerSequencingPrimer", dataType: "select", disable: false},
                    ]
                },
                {
                    colSize: 12,
                    fields: [
                        {name: "wildtypeControlFile", dataType: "text", disable: false},
                        {name: "additionalNote", dataType: "text", disable: false},
                    ]
                },
            ],
            schema: yup.object({
                plasmidId: yup.string().matches(/^PL[0-9]+/, "Must start with PL followed by a number. For example: PL2458"),
                plateId: yup.string()
                    .matches(
                        /^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}|dotmatics-[0-9]{6})$/,
                        "Must either be an UUID format (example: 4628a038-aa77-4e44-89dd-b253d1dffd8c) or dotmatics-{{experiment ID}} (example: dotmatics-141068)"
                    ),
                gene: yup.string().required(),
                guideSequence: yup.string().matches(/^[ATGCatgc]{20}$/, "Must have exact 20 letters of A, T, G or C").required(),
                cellLine: yup.string().matches(/^[A-Za-z0-9]+$/, "Must only contains upper cases, lower cases and number, no space or special character").required(),
                method: yup.string().required(),
                indelPercentage: yup.number().integer().min(0).max(100),
                modelFit: yup.number().min(0).max(1),
                knockoutScore: yup.number().integer().min(0).max(100).required(),
                collectionTimepoint: yup.string().required(),
                cellSelectionMarker: yup.string().required(),
                deliveryMethod: yup.string().required(),
                owner: yup.string().required(),
                project: yup.string().required(),
                additionalNote: yup.string(),
                forwardPrimerSequence: yup.string().matches(/^[ATGCatgc]+$/, "Must only contains A, T, G, C").required(),
                reversePrimerSequence: yup.string().matches(/^[ATGCatgc]+$/, "Must only contains A, T, G, C").required(),
                annealingTemp: yup.number().min(45).max(60).required(),
                additive: yup.string().required(),
                polymerase: yup.string().required(),
                ampliconSizeInBp: yup.number().min(200).max(1000).required(),
                sangerSequencingPrimer: yup.string().required(),
                wildtypeControlFile: yup.string().matches(/^https:\/\/enginebiosciences.sharepoint.com\//, "It must be a sharable SharePoint link to a zip file contains all .ab1 files.").required(),
            }),
            initialValues: props.initialValues === null ? {
                id: "",
                plasmidId: "",
                plateId: "",
                gene: "",
                cellLine: "",
                guideSequence: "",
                method: "",
                indelPercentage: "",
                modelFit: "",
                knockoutScore: "",
                collectionTimepoint: "",
                cellSelectionMarker: "",
                deliveryMethod: "",
                owner: "",
                project: "",
                additionalNote: "",
                lastModified: "",
                forwardPrimerSequence:  "",
                reversePrimerSequence:  "",
                annealingTemp:          "",
                additive:               "",
                polymerase:             "",
                ampliconSizeInBp:       "",
                sangerSequencingPrimer: "",
                wildtypeControlFile:    "",
            } : props.initialValues,
            submissionStatus: {
                status: ApiCallStatus.Loading,
                message: ""
            }
        }
    }

    onSubmit(values: any, formikHelpers: FormikHelpers<FormikValues>): void | Promise<any> {
        formikHelpers.setSubmitting(true)
        this.setState({
            submissionStatus: {
                status: ApiCallStatus.Loading,
                message: ""
            }
        }, () => {
            if (values["knockoutScore"] && values["indelPercentage"] && values["knockoutScore"] > values["indelPercentage"]) {
                return this.setFieldError(formikHelpers, "knockoutScore", "Knockout score can't be greater than indel percentage")
            }

            if (values["forwardPrimerSequence"] === values["reversePrimerSequence"]) {
                return this.setFieldError(formikHelpers, "reversePrimerSequence", "Forward and reverse primer sequence must be different")
            }

            if (values["forwardPrimerSequence"].includes(values["guideSequence"]) || values["reversePrimerSequence"].includes(values["guideSequence"])) {
                return this.setFieldError(formikHelpers, "guideSequence", "Guide sequence must not exists inside forward or reverse primer sequence")
            }

            if (values["deliveryMethod"].toLowerCase() === "lentivirus") {
                if (!values["plasmidId"]) {
                    return this.setFieldError(formikHelpers,"plasmidId", "Plasmid ID is required if delivery method is lentivirus.")
                }
                if (!values["plateId"]) {
                    return this.setFieldError(formikHelpers,"plateId", "Plate ID is required if delivery method is lentivirus.")
                }
            } else {
                if (values["plateId"]) {
                    return this.setFieldError(formikHelpers,"plateId", "Non-lentivirus plate shouldn't be on enginebio.io. Upload it to Dotmatics instead.")
                }
            }

            // We had a bug that second submit result in Error 1062: Duplicate entry '47f4e8cd-90f2-472e-b74a-10305619df01' for key 'PRIMARY'
            // To fix this we generate new UUID everytime submit button is triggered with PUT (but not PATCH).
            if (this.props.view === View.Import) {
                values["id"] = uuidv4()
            }

            values["guideSequence"] = values["guideSequence"].toUpperCase()

            if (this.props.view === View.Import) {
                this.apiPut(values, formikHelpers)
            } else if (this.props.view === View.Update) {
                this.apiPatch(values, formikHelpers)
            }
        })
    }

    setFieldError(formikHelpers: FormikHelpers<FormikValues>, fieldName: string, message: string): void {
        formikHelpers.setFieldError(fieldName, message)
        formikHelpers.setSubmitting(false)
    }

    apiPut(values: any, formikHelpers: FormikHelpers<FormikValues>) {
        UsageTracking.recordEvent({
            module: FrontendModule.DataCatalog,
            event: EventType.Import,
            table: TABLE_NAME,
            database: gppApiConsts.DATABASE_NAME,
            keywords: "", // Comma seperated keywords is splited to seperate Cloudwatch log at backend.
        })
        API.put(
            gppApiConsts.API_NAME,
            gppApiConsts.tableData.path.TABLE_DATA,
            {
                body: {
                    table: TABLE_NAME,
                    data: values,
                },
            }).then(response => {
            logger.debug("Response from API", response)
            DataAudit.recordEvent({
                database: gppApiConsts.DATABASE_NAME,
                table: TABLE_NAME,
                rowId: values["id"],
                updateNote: "",
                dataObject: values,
            })
            // Set success message
            this.setState({
                submissionStatus: {
                    status: ApiCallStatus.Completed,
                    message: "Indel performance submitted successfully"
                }
            })
        }).catch(err => {
            logger.error(err)
            if (err.response) {
                if (err.response.status >= 500 || err.response.status < 400) {
                    this.setState({
                        submissionStatus: {
                            status: ApiCallStatus.Error,
                            message: "Something wrong, please try again"
                        }
                    })
                } else {
                    this.setState({
                        submissionStatus: {
                            status: ApiCallStatus.Error,
                            message: err.response.data.message
                        }
                    })
                }
            }
        }).finally(() => {
            formikHelpers.setSubmitting(false)
        })
    }

    apiPatch(values: any, formikHelpers: FormikHelpers<FormikValues>) {
        UsageTracking.recordEvent({
            module: FrontendModule.DataCatalog,
            event: EventType.Update,
            table: TABLE_NAME,
            database: gppApiConsts.DATABASE_NAME,
            keywords: "", // Comma seperated keywords is splited to seperate Cloudwatch log at backend.
        })
        API.patch(
            gppApiConsts.API_NAME,
            gppApiConsts.tableData.path.TABLE_DATA,
            {
                body: {
                    table: TABLE_NAME,
                    data: values,
                    updateNote: values["updateNote"],
                },
            }).then(response => {
            DataAudit.recordEvent({
                database: gppApiConsts.DATABASE_NAME,
                table: TABLE_NAME,
                rowId: values["id"],
                updateNote: values["updateNotes"],
                dataObject: values,
            })
            logger.debug("Response from API", response)
            // Set success message
            this.setState({
                submissionStatus: {
                    status: ApiCallStatus.Completed,
                    message: "Updated successfully! Record id is " + values["id"]
                }
            })
        }).catch(err => {
            logger.error(err)
            if (err.response) {
                if (err.response.status >= 500 || err.response.status < 400) {
                    this.setState({
                        submissionStatus: {
                            status: ApiCallStatus.Error,
                            message: "Something wrong, please try again"
                        }
                    })
                } else {
                    this.setState({
                        submissionStatus: {
                            status: ApiCallStatus.Error,
                            message: err.response.data.message
                        }
                    })
                }
            }
        }).finally(() => {
            formikHelpers.setSubmitting(false)
        })
    }

    render() {
        return <Card bg={"light"}>
            {this.props.displayHeader && <Card.Header><b>Add new indel</b></Card.Header>}
            <Card.Body>
                <HtmlForm
                    databaseName={gppApiConsts.DATABASE_NAME}
                    tableName={"indel_performance"}
                    initialValues={this.state.initialValues}
                    schema={this.state.schema}
                    rowsOfFormFields={this.state.rowsOfFormFields}
                    onSubmit={this.onSubmit.bind(this)}
                    submissionStatus={this.state.submissionStatus}
                    autofillOwnerByLogInId={this.props.autofillOwnerByLogInId}
                    view={this.props.view}
                />
            </Card.Body>
        </Card>
    }
}