import React from 'react';
import BootstrapTable, {ColumnDescription} from "react-bootstrap-table-next";
import filterFactory, {Comparator, selectFilter, textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import "./SangerQCHistoryTable.css"
import {API, Logger, Storage} from "aws-amplify";
import {sangerQcApiConsts} from "./Const";
import {errorMessage} from "../Alerts/Const";
import {Spinner} from "react-bootstrap";
import {SangerQCPipeline} from "./Const";

const logger = new Logger("SangerQCHistoryTable.tsx")

interface SangerQCHistoryTableProps {
    showSangerQCReport(rowIndex: string): void
    refreshToken: string
    showErrorAlert(message: string): void
}

interface SangerQCHistoryTableState {
    columns: ColumnDescription[]
    rows: SangerQCHistoryTableRow[]
    loading: boolean
}

export interface SangerQCHistoryTableRow {
    uuid: string,
    description: string,
    userName: string,
    stage: string,
    slNumber: string,
    timestamp: string,
    status: string,
    errorMessage: string,
    s3URL: string,
    batchJobURL: string,
    deleted: boolean,
}

let slNumbers: {value: number, label: string}[] = []
// TODO - How to dynamically get these values?
const stageSelectOptions = {
    "Intermediate": "Intermediate",
    "Final": "Final"
}

export class SangerQCHistoryTable extends React.Component<SangerQCHistoryTableProps, SangerQCHistoryTableState> {
    constructor(props: SangerQCHistoryTableProps) {
        super(props);
        this.state = {
            loading: false,
            columns: [
                {
                    dataField: "timestamp",
                    text: "Date",
                    sort: true,
                    formatter: cell => {
                        if (!cell) {
                            return "";
                        }
                        return new Date(cell).toLocaleDateString();
                    },
                    classes: "sanger-qc-history-table-date",
                },
                {dataField: "uuid", text: "Report ID", hidden: true},
                {
                    dataField: "description",
                    text: "",
                    filter: textFilter({
                        placeholder: "Search description"
                    }),
                    classes: "sanger-qc-history-table-description",
                },
                {
                    dataField: "userName",
                    text: "",
                    filter: textFilter({
                        placeholder: "Search user"
                    }),
                    classes: "sanger-qc-history-table-username",
                },
                {
                    dataField: "slNumber",
                    text: "",
                    filter: selectFilter({
                        options: slNumbers,
                        placeholder: "SL",
                        className: "form-select",
                    }),
                    classes: "sanger-qc-history-table-select-sl-numbers",
                },
                {
                    dataField: "stage",
                    text: "",
                    filter: selectFilter({
                        options: stageSelectOptions,
                        placeholder: "All stages",
                        className: "form-select",
                        comparator: Comparator.LIKE
                    }),
                    classes: "sanger-qc-history-table-select-stage"
                },
                {
                    dataField: "status",
                    text: "Status",
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                        switch (cell.toLowerCase()) {
                            case SangerQCPipeline.Status.IN_PROGRESS.toLowerCase():
                                return (<span className="text-primary fw-bold">{cell}</span>)
                            case SangerQCPipeline.Status.COMPLETED.toLowerCase():
                                return (<span className="text-success fw-bold">{cell}</span>)
                            case SangerQCPipeline.Status.FAILED.toLowerCase():
                                return (<span className="text-danger fw-bold">{cell}</span>)
                            default:
                                return (<span className="text-dark fw-bold">{cell}</span>)
                        }
                    }
                },
            ],
            rows: [{
                description: "loading",
                uuid: "loading",
                userName: "loading",
                stage: "loading",
                slNumber: "loading",
                timestamp: "loading",
                status: "loading",
                errorMessage: "loading",
                deleted: false,
                s3URL:  "loading",
                batchJobURL: "loading",
            }],
        }
    }

    componentDidMount() {
        this.listSlNumbers()
        this.listAllPipelines()
    }

    listSlNumbers() {
        Storage
            .list("sl-config/", {
                level: "public"
            }).then((response: string | any[]) => {
                // Skip the first element which is folder name
                for (let i=1; i < response.length; i++) {
                    if (response[i].key !== undefined) {
                        // Convert sl-config/SL4.config.txt to 4
                        let slStr: string = response[i].key!.toString().split("/")[1]
                        let slNumber: number = parseInt(slStr.charAt(2))
                        const exists = slNumbers.some(e => e.value === slNumber)
                        if (!exists) {
                            slNumbers.push({value: slNumber, label: slStr.charAt(2)})
                        }
                    }
                }
            }).catch((err: any) => {
                logger.error(err)
                this.props.showErrorAlert(errorMessage(err))
            })
    }

    listAllPipelines() {
        this.setState({
            loading: true
        })
        API.get(sangerQcApiConsts.API_NAME, sangerQcApiConsts.Path.LIST_ALL, {}).then(response => {
            logger.info("Response from API - " + response)
            this.setState({
                rows: response.data
            })
        }).catch(err => {
            this.props.showErrorAlert(err + ". Please refresh and try again.")
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    componentDidUpdate(prevProps: Readonly<SangerQCHistoryTableProps>, prevState: Readonly<SangerQCHistoryTableState>, snapshot?: any) {
        if (this.props.refreshToken !== prevProps.refreshToken) {
            logger.info("Refresh token changed, refresh SangerQCHistoryTable")
            this.listAllPipelines()
        }
    }

    render() {
        if (this.state.loading) {
            return (<>
                <Spinner
                    animation="border"
                    size="sm"
                    variant="primary"
                >
                </Spinner>
                <span className="text-primary m-2 fw-bold">Loading all jobs ...</span>
            </>)
        }

        return (
            <>
                <h3>All submitted jobs</h3>
                <BootstrapTable
                    bordered
                    hover
                    keyField="uuid"
                    columns={this.state.columns}
                    data={this.state.rows}
                    filter={filterFactory()}
                    defaultSorted={[
                        {
                            dataField: "timestamp",
                            order: "desc"
                        }
                    ]}
                    pagination={
                        paginationFactory({
                            sizePerPage: 5,
                            sizePerPageList: [5, 10, 20, 40]
                        })
                    }
                    classes="sanger-qc-history-table"
                    rowEvents={
                        {
                            onClick: (e, row, rowIndex) => {
                                logger.debug(row)
                                this.props.showSangerQCReport(row.uuid)
                            },
                        }
                    }
                />
            </>
        );
    }
}
