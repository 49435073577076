import React from "react";
import {Card, Col, Nav, Row, Tab} from "react-bootstrap";
import {View} from "./GenGenPlate/Enum";
import {GenGenPlateMetadataForm} from "./GenGenPlate/Metadata/GenGenPlateMetadataForm";
import {GenGenPlateMapForm} from "./GenGenPlate/PlateMap/GenGenPlateMapForm";
import {GenGenPlateRawDataForm} from "./GenGenPlate/RawData/GenGenPlateRawDataForm";
import {IndelForm} from "./GuidePlasmidIndel/IndelForm";
import {PlasmidForm} from "./GuidePlasmidIndel/PlasmidForm";
import {WesternBlotForm} from "./GuidePlasmidIndel/WesternBlotForm";

export class Import extends React.Component<any, any> {
    render() {
        return <Tab.Container id="left-tabs-example" defaultActiveKey="plasmid">
            <Row>
                <Col lg={2}>
                    <Card bg={"light"}>
                        <Card.Body>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item role={"button"}>
                                    <Nav.Link eventKey="plasmid">Plasmid</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={"text-secondary"}>Gen-gen experiment</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"} className={"ms-2"}>
                                    <Nav.Link eventKey="gen_gen_plate_metadata">Step 1. Metadata</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"} className={"ms-2"}>
                                    <Nav.Link eventKey="gen_gen_plate_map">Step 2. Plate Map</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"}  className={"ms-2"}>
                                    <Nav.Link eventKey="gen_gen_plate_raw_data">Step 3. Raw Data</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"}  className={"ms-2"}>
                                    <Nav.Link eventKey="indel_performance">Step 4. Indel</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"}  className={"ms-2"}>
                                    <Nav.Link eventKey="western_blot">Step 5. Western Blot (optional)</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={"text-secondary"}>Pharm-gen experiment</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"} className={"ms-2"}>
                                    <Nav.Link eventKey="pharm_gen_indel_performance">Step 1. Indel</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={10}>
                    <Tab.Content>
                        <Tab.Pane eventKey="plasmid">
                            <Row className={"g-0 mb-3"}>
                                <PlasmidForm view={View.Import}/>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="indel_performance">
                            <Row className={"g-0"}>
                                <IndelForm view={View.Import} />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="western_blot">
                            <Row className={"g-0"}>
                                <WesternBlotForm view={View.Import} />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="gen_gen_plate_metadata">
                            <Row className={"g-0"}>
                                <GenGenPlateMetadataForm view={View.Import} />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="gen_gen_plate_map">
                            <Row className={"g-0"}>
                                <GenGenPlateMapForm view={View.Import} />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="gen_gen_plate_raw_data">
                            <Row className={"g-0"}>
                                <GenGenPlateRawDataForm view={View.Import} />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"pharm_gen_indel_performance"}>
                            <Row className={"g-0"}>
                                <IndelForm view={View.Import} />
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    }
}