import React from "react";
import {SearchBoxComparator} from "../../Common/Enum";
import "../../Common/Modal.css"
import {SearchResultTableWithUpdateUI} from "../../Common/SearchResultTableWithUpdateUI";
import {View} from "../Enum";
import {genGenPlateApiConst} from "../GenGenPlateApiConst";
import {GenGenPlateTableDataApi} from "../GenGenPlateTableDataApi";
import {GenGenPlateMetaData} from "../Metadata/GenGenPlateMetadataForm";
import {GenGenPlateMap} from "../PlateMap/GenGenPlateMapForm";
import {GenGenPlateRawData} from "../RawData/GenGenPlateRawDataForm";
import {GenGenPlateAggregatedForm} from "./GenGenPlateAggregatedForm";

export interface GenGenPlateAggregatedGetResponse {
    metadata: GenGenPlateMetaData
    plateMap?: GenGenPlateMap
    rawData?: GenGenPlateRawData[]
}

export interface GenGenPlateAggregatedTableRow extends GenGenPlateMetaData {}

interface GenGenPlateAggregatedSearchResultTableProps {
    refreshToken: string
    keywords: string
    comparator: SearchBoxComparator
    enableFilter: boolean
    view: View
}

interface GenGenPlateAggregatedSearchResultTableState {}

export class GenGenPlateAggregatedSearchResultTable extends React.Component<GenGenPlateAggregatedSearchResultTableProps, GenGenPlateAggregatedSearchResultTableState> {
    static defaultProps = {
        editable: true,
        enableFilter: true,
    }
    
    render() {
        return <SearchResultTableWithUpdateUI
            refreshToken={this.props.refreshToken}
            databaseName={genGenPlateApiConst.DATABASE_NAME}
            // Although this is aggregated table, the SearchResultTableWithUpdateUI still show the same columns as plate metadata, and the HtmlForm is custom-built and not depends on this props
            tableName={genGenPlateApiConst.tableName.AGGREGATED}
            keywords={this.props.keywords}
            columns={""}
            comparator={this.props.comparator}
            keyField={"id"}
            title={"Gen Gen Plate Map"}
            legends={<></>}
            rowClasses={row => "table-light"}
            columnsHidden={["downloadLink", "deliveryMethod"]}
            columnsDisplayInOrder={["plateId", "project", "geneAs", "geneBs", "cellLine", "replicateNumber", "dotmaticsExperimentId", "owner"]}
            enableFilter={this.props.enableFilter}
            modalClassName={"modal-larger-width"}
            modalForm={GenGenPlateAggregatedForm}
            view={this.props.view}
            searchTable={GenGenPlateTableDataApi.searchTable}
    />;
    }
}