import {Storage} from "aws-amplify";
import React from "react";
import {Alert, ButtonGroup, Col, Container, Image, Row, Spinner, Table} from "react-bootstrap";
import {ArrowClockwise, CheckCircleFill, Image as ImageIcon, Table as TableIcon} from "react-bootstrap-icons";
import {DismissibleAlert} from "../Alerts/DismissibleAlert";
import {ApiCallStatus, BUCKET_NAME, SynergyFinderPipelineStatus} from "./Const"
import {DownloadButton} from "./DownloadButton";
import {Pipeline} from "./SynergyFinderHistoryTable";

interface SynergyFinderReportProps {
    selectedReports: Pipeline[]
    refreshHistoryTable(): void
}

interface SynergyFinderReportState {
    selectedReportDownloadStatus: Record<string, ApiCallStatus>
    selectedReportErrorMessage: Record<string, string>
    selectedReportImageUri: Record<string, string>
}

export class SynergyFinderReport extends React.Component<SynergyFinderReportProps, SynergyFinderReportState> {
    constructor(props: SynergyFinderReportProps) {
        super(props);
        this.state = {
            selectedReportErrorMessage: {},
            selectedReportImageUri: {},
            selectedReportDownloadStatus: {},
        }
    }

    componentDidMount() {
        if (this.props.selectedReports.length > 0) {
            this.getCalculation()
        }
    }

    componentDidUpdate(prevProps: Readonly<SynergyFinderReportProps>, prevState: Readonly<SynergyFinderReportState>, snapshot?: any) {
        if (prevProps.selectedReports !== this.props.selectedReports) {
            this.getCalculation()
        }
    }

    getCalculation() {
        let selectedReportDownloadStatus: Record<string, ApiCallStatus> = {}
        let selectedReportErrorMessage: Record<string, string> = {}
        let selectReportImageUri: Record<string, string> = {}
        for (let selectedReport of this.props.selectedReports) {
            selectedReportDownloadStatus[selectedReport.uuid] = ApiCallStatus.Loading
            selectedReportErrorMessage[selectedReport.uuid] = ""
            selectReportImageUri[selectedReport.uuid] = ""
        }

        this.setState({
            selectedReportDownloadStatus: selectedReportDownloadStatus,
            selectedReportErrorMessage: selectedReportErrorMessage,
        }, () => {
            for (let selectedReport of this.props.selectedReports) {
                if (selectedReport.calculationStatus !== SynergyFinderPipelineStatus.COMPLETED) {
                    // If calculation is not ready, no need to download image, hence api call for downloading S3 is completed
                    selectedReportDownloadStatus[selectedReport.uuid] = ApiCallStatus.Completed
                    this.setState({
                        selectedReportDownloadStatus: selectedReportDownloadStatus,
                    })
                    continue
                }

                let s3Key: string = selectedReport.s3KeyOutputGraphImageSmall
                if (s3Key.startsWith("public/")) {
                    s3Key = s3Key.replace("public/", "")
                }
                Storage.get(s3Key, {
                    bucket: BUCKET_NAME,
                    download: false,
                }).then((result) => {
                    selectedReportDownloadStatus[selectedReport.uuid] = ApiCallStatus.Completed
                    selectReportImageUri[selectedReport.uuid] = result
                    this.setState({
                        selectedReportImageUri: selectReportImageUri,
                        selectedReportDownloadStatus: selectedReportDownloadStatus
                    })
                }).catch((err: string) => {
                    selectedReportDownloadStatus[selectedReport.uuid] = ApiCallStatus.Error
                    selectedReportErrorMessage[selectedReport.uuid] = err + ""
                    this.setState({
                        selectedReportDownloadStatus: selectedReportDownloadStatus,
                        selectedReportErrorMessage: selectedReportErrorMessage
                    })
                })
            }
        })
    }

    render() {
        if (this.props.selectedReports.length === 0) {
            return (<></>)
        }

        return <>
            {this.props.selectedReports.map((selectedReport, i) => {
                let reportRendering = <>
                    <Row>
                        <Col md={12}>
                            <Table bordered striped>
                                <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Gene status</th>
                                    <th>Drug 1</th>
                                    <th>Drug 2</th>
                                    <th>Cell line</th>
                                    <th>Calculation method</th>
                                    <th>Download</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{selectedReport.description}</td>
                                    <td>{selectedReport.geneStatus}</td>
                                    <td>{selectedReport.drug1}</td>
                                    <td>{selectedReport.drug2}</td>
                                    <td>{selectedReport.cellLine}</td>
                                    <td>{selectedReport.calculationMethod}</td>
                                    <td>
                                        <ButtonGroup>
                                            <DownloadButton className={"me-1"} s3Bucket={BUCKET_NAME} s3Keys={[selectedReport.s3KeyOutputExcel]} variant={"primary"} icon={<TableIcon />} />
                                            <DownloadButton s3Bucket={BUCKET_NAME} s3Keys={[selectedReport.s3KeyOutputGraphImage]} variant={"primary"} icon={<ImageIcon />} />
                                        </ButtonGroup>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Image src={this.state.selectedReportImageUri[selectedReport.uuid]} width={"100%"} />
                        </Col>
                    </Row>
                </>

                if (this.state.selectedReportDownloadStatus[selectedReport.uuid] === ApiCallStatus.Loading) {
                    reportRendering = <>
                        <p style={{marginBottom: 20}}>{selectedReport.description}</p>
                        <Spinner
                            animation="border"
                            size="sm"
                            variant="primary"
                        >
                        </Spinner>
                        <span className="text-primary m-2 fw-bold">Calculating synergy, may take few minutes ...</span>
                    </>
                } else if (this.state.selectedReportDownloadStatus[selectedReport.uuid] === ApiCallStatus.Error) {
                    reportRendering = <>
                        <p style={{marginBottom: 20}}>{selectedReport.description}</p>
                        <DismissibleAlert message={this.state.selectedReportErrorMessage[selectedReport.uuid]}/>
                    </>
                } else if (this.state.selectedReportDownloadStatus[selectedReport.uuid] === ApiCallStatus.Completed) {
                    if (selectedReport.calculationStatus === SynergyFinderPipelineStatus.FAILED) {
                        reportRendering = <>
                            <p style={{marginBottom: 20}}>{selectedReport.description}</p>
                            <DismissibleAlert message={selectedReport.errorMessage}/>
                        </>
                    } else if (selectedReport.calculationStatus === SynergyFinderPipelineStatus.IN_PROGRESS) {
                        reportRendering = <>
                            <p style={{marginBottom: 20}}>{selectedReport.description}</p>
                            <Alert variant={"info"}>
                                Still calculating, please <span className="text-primary fw-bold"><ArrowClockwise /> History table</span> until status change to <CheckCircleFill className={"ms-1"} color={"rgba(var(--bs-success-rgb),var(--bs-bg-opacity))"}/>.
                            </Alert>
                            <Alert variant={"info"}>
                                It may take from <span className="text-primary fw-bold">30 seconds to 2 minutes.</span>
                            </Alert>
                        </>
                    }
                }

                return <div key={selectedReport.uuid}>
                    <hr/>
                    <Container className="p-4 bg-light wide-container">
                        {reportRendering}
                    </Container>
                </div>
            })}
        </>
    }
}