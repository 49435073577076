import {API} from "aws-amplify";
import {SearchBoxComparator} from "../Common/Enum";
import {gppApiConsts} from "./GppApiConsts";
import {Indel} from "./IndelForm";
import {Plasmid} from "./PlasmidForm";
import {WesternBlot} from "./WesternBlotForm";

export class GppTableDataApi {
    static searchTable(keywords: string, columns: string, table: string, comparator: SearchBoxComparator): Promise<Indel[] | Plasmid[] | WesternBlot[]> {
        return API.get(
            gppApiConsts.API_NAME,
            gppApiConsts.tableData.path.TABLE_DATA,
            {
                queryStringParameters: {
                    table: table,
                    database: gppApiConsts.DATABASE_NAME,
                    keywords: keywords.replace(/\s/g,''), // Remove all white spaces, in case user copy/paste from Excel
                    columns: columns.replace(/\s/g,''),
                    comparator: comparator,
                },
            }).then(response => response["data"])
    }
}