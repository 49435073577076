import React from "react";
import { Alert } from "react-bootstrap";

interface ErrorAlertProps {
    message: string
    className?: string
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
}

interface ErrorAlertState {
    show: boolean
    message: string
    stateChangedOnly: boolean
}

export class DismissibleAlert extends React.Component<ErrorAlertProps, ErrorAlertState> {
    constructor(props: ErrorAlertProps) {
        super(props);
        this.state = {
            stateChangedOnly: false,
            show: false,
            message: "",
        }
    }

    static getDerivedStateFromProps(nextProps: ErrorAlertProps, prevState: ErrorAlertState) {
        if (nextProps.message === "" || nextProps.message === null || nextProps.message === undefined) {
            return {
                show: false,
                message: nextProps.message
            }
        }

        // Since getDerivedStateFromProps is triggered for both props change and setState
        // And during close() we did setState
        // Need a way to tell the difference between 2 scenario:
        // 1. New values for this.props.message being passed to component --> display error alert.
        // 2. Close button clicked --> setState reset errorMessage to empty, while nextProps remains the same --> close the error alert.
        // -> the different is:
        // - for 1 -> getDerivedStateFromProps triggered while no state is changed
        // - for 2 -> getDerivedStateFromProps triggered due to state changed. But since getDerivedStateFromProps didn't have access to current state
        // - can't use shouldComponentUpdate because shouldComponentUpdate can't update state,
        // thus cannot prevent getDerivedStateFromProps from wrongly update the state
        if (prevState.stateChangedOnly) {
            return {
                stateChangedOnly: false
            }
        }

        if (prevState.message === "" && nextProps.message !== "") {
            return {
                show: true,
                message: nextProps.message
            }
        }
    }

    close() {
        this.setState({
            show: false,
            message: "",
            stateChangedOnly: true,
        })
    }

    render() {
        if (this.state.show) {
            return (
                <Alert
                    className={this.props.className}
                    variant={this.props.variant ? this.props.variant : "danger"}
                    onClose={() => this.close()} dismissible>
                    <p>{this.props.message.includes("403") ? `${this.props.message} (re-login may help)` : this.props.message}</p>
                </Alert>
            )
        }
        return null
    }
}