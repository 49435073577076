import {Auth} from "@aws-amplify/auth";
import {Logger} from "aws-amplify";
import React from "react";
import {Alert, Modal} from "react-bootstrap";
import "./Common/Modal.css"
import {View} from "./GenGenPlate/Enum";

const logger = new Logger("RowDataModal.tsx")

interface RowDataModalProps {
    show: boolean
    tableName: string
    initialValues: any
    closeModal: any
    modalFullscreen: true | string
    view: View
    modalClassName: string
    modalForm: React.ComponentClass<any, any>
}

interface RowDataModalState {
    warningMsg: string
}

export class RowDataModal extends React.Component<RowDataModalProps, RowDataModalState> {
    public static defaultProps = {
        modalFullscreen: "",
        modalClassName: ""
    }

    constructor(props: any) {
        super(props);
        this.state = {
            warningMsg: ""
        }
    }

    // No need for componentDidMount, since RowDataModal already get rendered by default at TableDataApiWithRowDataModal

    componentDidUpdate(prevProps: Readonly<RowDataModalProps>, prevState: Readonly<RowDataModalState>, snapshot?: any) {
        if (!prevProps.show && this.props.show) {
            // Only check for warning if the modal change from close to open.
            this.warnings()
        }
    }

    warnings(): void {
        // Warning if update somebody else data
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (this.props.view !== View.Read &&
                this.props.initialValues["owner"] &&
                this.props.initialValues["owner"] !== user.attributes.email) {
                this.setState({
                    warningMsg: "You are updating data belongs to " + this.props.initialValues["owner"]
                })
            } else {
                this.setState({
                    warningMsg: ""
                })
            }
            logger.debug("warnings() state = ", this.state)
        }).catch(err => {
            logger.error(err)
        });
    }

    render() {
        return <Modal
            show={this.props.show} onHide={this.props.closeModal} size={"lg"}
            centered fullscreen={this.props.modalFullscreen}
            backdrop="static" keyboard={false}
            dialogClassName={this.props.modalClassName}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                {this.state.warningMsg && <Alert variant={"warning"}>
                    <Alert.Heading>Warning!</Alert.Heading>
                    {this.state.warningMsg}
                </Alert>}
                <this.props.modalForm
                            displayHeader={false}
                            autofillOwnerByLogInId={false}
                            initialValues={this.props.initialValues}
                            view={this.props.view}
                />
            </Modal.Body>
        </Modal>;
    }
}