import {currentEnv, shortenEnv} from "../../../Const";

export const genGenPlateApiConst: {
    API_NAME: string,
    API_ENDPOINT: string,
    DATABASE_NAME: string,
    path: any,
    DATA_FIELD: Record<string, string>,
    tableName: any
} = {
    API_NAME: `${shortenEnv}-gen-gen-data`,
    API_ENDPOINT: `https://${shortenEnv}-gen-gen-data.enginebio.io`,
    DATABASE_NAME: "plate_data",
    path: {
        MAP: "/platemap",
        PLATE_METADATA: "/metadata",
        TABLE_METADATA_ENUM: "/table-metadata/columns-enums",
        AGGREGATED: "/search/aggregated",
        CALCULATION: "/calculation",
        RAW_DATA: "/rawdata"
    },
    // gen-gen-plate api is a special case where all data returned via single path search/aggregated,
    // not a great idea, but due to time constraint we'll stick with it for now. Because all data is aggregated, data return in format of:
    // responseBody:
    //   application/json:
    //     schema:
    //       type: object
    //       properties:
    //         data:
    //           type: object
    //           properties:
    //              metadata: --> this is DATA_FIELD we are mentioning below, so front-end know which properties here mapping to what tableName
    //                type: object
    //              metadata:
    //                type: object
    //              metadata:
    //                type: object
    //
    DATA_FIELD: {
        "gen_gen_plate": "metadata",
        "plate_raw_data": "rawData",
        "plate_well": "plateMap",
    },
    tableName: {
        PLATE_METADATA: "gen_gen_plate",
        MAP: "plate_well_api_model", // TODO Instead of keeping this metadata at Glue data catalog, since it's just a dummy table, why not keeping it here?
        RAW_DATA: "plate_raw_data_api_model", // TODO Instead of keeping this metadata at Glue data catalog, since it's just a dummy table, why not keeping it here?
        AGGREGATED: "gen_gen_plate"
    }
}

export const BUCKET_NAME = currentEnv === "develop" ? "dev-data-catalog" : "prod-data-catalog"