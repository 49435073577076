import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {Bar, BarChart, CartesianGrid, Cell, ErrorBar, Tooltip, XAxis, YAxis} from "recharts";
import FileSaver from "file-saver";
import {CurrentPngProps} from "recharts-to-png";

interface GenGenPlateCalculatedResultChartProps extends CurrentPngProps {
    chartData: any[]
}

interface GenGenPlateCalculatedResultChartState {}

const chartColor: Map<string, string> = new Map<string, string>([
    ["ntc_plasmid", "#ff9307d6"],
    ["indel_control", "#6c757d"],
    ["positive_control", "#cf2d04"],
    ["single_guide_plasmid", "#28a745"],
    ["combo_plasmid", "#007bff"],
])
const chartErrorBarColour: string = "#6f42c1"

export class GenGenPlateCalculatedResultChart extends React.Component<GenGenPlateCalculatedResultChartProps, GenGenPlateCalculatedResultChartState> {
    handleDownload = async () => {
        const png = await this.props.getPng();
        if (png) {
            FileSaver.saveAs(png, "chart.png");
        }
    };

    render() {
        return <>
            <Row className={"mt-2"}>
                <Col md={12}>
                    <Button className={"float-end"} onClick={this.handleDownload.bind(this)} disabled={this.props.isLoading}>
                        {
                            this.props.isLoading ? "Downloading..." : "Download Chart"
                        }
                    </Button>
                </Col>
                <Col md={12}>
                    <div style={{overflowX: "scroll"}}>
                        <BarChart
                            id={"bar-chart"}
                            width={1000}
                            height={450}
                            data={this.props.chartData}
                            ref={this.props.chartRef}
                            style={{
                                fontSize: "0.6rem"
                            }}
                        >
                            <CartesianGrid strokeDasharray="1 1"/>
                            {/*Interval = 0 will show bar name for all bar */}
                            <XAxis dataKey="name" angle={-22.5} textAnchor="end" height={120} interval={0}/>
                            <YAxis/>
                            <Tooltip label={"name"}/>
                            <Bar dataKey="avg" fill="#82ca9d" barSize={20}>
                                <ErrorBar dataKey={"errors"} stroke={chartErrorBarColour}/>
                                {
                                    this.props.chartData.map((entry: any, index: number) => {
                                        return <Cell
                                            key={`gen-gen-plate-cell-${index}`}
                                            fill={chartColor.get(entry.category)}
                                            type={entry.category}/>
                                    })
                                }
                            </Bar>
                        </BarChart>
                    </div>
                </Col>
            </Row>
            <Row className={"mt-2"}>
                <Col md={12}>
                    {
                        Array.from(chartColor.keys()).map((category) =>
                            <span key={category} className={"badge me-2"} style={{backgroundColor: chartColor.get(category)}}>{category}</span>
                        )
                    }
                </Col>
            </Row>
        </>;
    }
}