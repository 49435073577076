import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {SangerQCInputForm} from "./SangerQCInputForm";
import {SangerQCHistoryTable} from "./SangerQCHistoryTable";
import {SangerQCReport} from "./SangerQCReport";
import { v4 as uuidv4 } from 'uuid';
import {DismissibleAlert} from "../Alerts/DismissibleAlert";

interface SangerQCState {
    sangerQCReport: {
        show: boolean
        reportIndex: string
    }
    sangerQCHistoryTable: {
        refreshToken: string
    }
    errorMessage: string
}

export class SangerQC extends React.Component<any, SangerQCState> {
    constructor(props: any) {
        super(props);
        this.state = {
            sangerQCReport: {
                show: false,
                reportIndex: "",
            },
            sangerQCHistoryTable: {
                refreshToken: uuidv4()
            },
            errorMessage: ""
        }
    }

    showSangerQCReport(reportIndex: string) {
        this.setState({
            sangerQCReport: {
                show: true,
                reportIndex: reportIndex,
            }
        })
    }

    hideSangerQCReport() {
        this.setState({
            sangerQCReport: {
                show: false,
                reportIndex: "",
            }
        })
    }

    refreshHistoryTable() {
        this.setState({
            sangerQCHistoryTable: {
                refreshToken: uuidv4()
            }
        })
    }

    showErrorAlert(message: string) {
        this.setState({
            errorMessage: message
        })
    }

    render() {
        return (
            <>
                <DismissibleAlert message={this.state.errorMessage}/>
                <Container className="p-4 bg-light" style={{maxWidth: "1600px"}}>
                    <Row>
                        <Col className="col-4">
                            <SangerQCInputForm
                                refreshHistoryTable={this.refreshHistoryTable.bind(this)}
                                showErrorAlert={this.showErrorAlert.bind(this)}
                            />
                        </Col>
                        <Col className="col-8">
                            <SangerQCHistoryTable
                                showSangerQCReport={this.showSangerQCReport.bind(this)}
                                refreshToken={this.state.sangerQCHistoryTable.refreshToken}
                                showErrorAlert={this.showErrorAlert.bind(this)}
                            />
                        </Col>
                    </Row>
                </Container>
                {/*//
                    Using React "key" to create new SangerQCReport.
                    https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
                    --> CANNOT because we need to pass reportIndex to SangerQCReport component, and key is not an accessible props
                */}
                <SangerQCReport
                    reportIndex={this.state.sangerQCReport.reportIndex}
                    show={this.state.sangerQCReport.show}
                    hideSangerQCReport={this.hideSangerQCReport.bind(this)}
                    refreshHistoryTable={this.refreshHistoryTable.bind(this)}
                />
            </>
        );
    }
}