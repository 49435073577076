import {API, Logger} from "aws-amplify";
import {USER_EMAIL} from "../Auth/LoginUser";
import {commonApiConsts} from "./Const";
import {v4 as uuidv4} from "uuid";

const logger = new Logger("DataAudit.ts")

export interface DataAuditEvent {
    id?: string
    database: string
    table: string
    rowId: string
    user?: string
    updateNote: string
    dataObject: any
    lastModified?: Date
}

const DEFAULT_VALUE_FOR_UPDATE_NOTE = "New entry added."

let dataAuditEventSendingQueue: DataAuditEvent[] = []
let dataAuditEventPendingQueue: DataAuditEvent[] = []
let retryRecord: Record<string, number> = {}
setInterval(sendToBackEnd, 10000)

function sendToBackEnd() {
    if (dataAuditEventPendingQueue.length === 0) {
        console.log("No usage tracking event in the queue")
        return
    }

    while (dataAuditEventPendingQueue.length > 0) {
        let event: DataAuditEvent | undefined = dataAuditEventPendingQueue.pop()
        if (event !== undefined) {
            dataAuditEventSendingQueue.push(event)
        }
    }

    console.log("dataAuditEventSendingQueue = ", dataAuditEventSendingQueue)

    API.post(
        commonApiConsts.API_NAME,
        "/data-audit",
        {
            body: {
                data: dataAuditEventSendingQueue
            },
        })
        .then(response => {
            dataAuditEventSendingQueue = []
        }).catch(err => {
            // Pop events back to pending queue
            logger.error(err)
            while (dataAuditEventSendingQueue.length > 0) {
                let event: DataAuditEvent | undefined = dataAuditEventSendingQueue.pop()
                if (event !== undefined && event.id !== undefined) {
                    if (retryRecord[event.id] === undefined) {
                        retryRecord[event.id] = 1
                    }
                    if (retryRecord[event.id] > 5) {
                        // Stop retry after 5 times
                        continue
                    }
                    if (retryRecord[event.id]) {
                        retryRecord[event.id] = retryRecord[event.id] + 1
                        dataAuditEventPendingQueue.push(event)
                    }
                }
            }
        }).finally(() => {

        })
}

export class DataAudit {
    // TODO - need to add record event to gen-gen-plate module also.
    static recordEvent(event: DataAuditEvent): void {
        event.id = uuidv4()
        event.user = USER_EMAIL
        event.lastModified = new Date()
        event.dataObject = JSON.stringify(event.dataObject)
        if (!event.updateNote || event.updateNote === "") {
            event.updateNote = DEFAULT_VALUE_FOR_UPDATE_NOTE
        }
        console.log("event before push ", event)
        dataAuditEventPendingQueue.push(event)
    }

    static searchEvent(keywordsCommaSeperated: string, column?: string): Promise<DataAuditEvent[]> {
        return API.get(
            commonApiConsts.API_NAME,
            "/data-audit",
            {
                queryStringParameters: {
                    keywords: keywordsCommaSeperated,
                    column: column
                }
            }).then(response => response["data"])
    }
}