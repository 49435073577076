import {shortenEnv} from "../../../Const";

export const gppApiConsts = {
    API_NAME: `${shortenEnv}-guide-primer-plasmid-api`,
    API_ENDPOINT: "https://" + shortenEnv + "-guide-primer-plasmid-api.enginebio.io",
    DATABASE_NAME: "gpp",
    hgnc: {
        path: {
            GET_GENE_SYMBOL:  "/hgnc/approved-symbols"
        }
    },
    tableMetadata: {
        path: {
            COLUMNS_ENUMS: "/table-metadata/columns-enums",
        }
    },
    plasmid: {
        path: {
            NEXT_ID: "/plasmid/next-id",
            GENE_A_GENE_B: "/plasmid/gene-a-gene-b"
        },
    },
    tableData: {
        path: {
            TABLE_DATA: "/table-data",
        }
    },
}