import React from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import {currentEnv} from "../../Const";

export class ExperimentalFeatures extends React.Component<any, any> {
    render() {
        let envPrefix: string = currentEnv === "production" ? "" : "develop."
        let echoURL = "https://" + envPrefix + "echo.enginebio.io"
        let tcgaMutFreqURL = "https://" + envPrefix + "tcga-mut-freq.enginebio.io"
        let fuelUrl: string = "https://" + envPrefix + "fuel.enginebio.io"

        return <>
                <h3 className={"mb-3"}>Data Visualization</h3>
                <Row className={"mt-2"}>
                    <Col lg="4">
                        <Card border="secondary" className={"bg-light"}>
                            <Card.Header>
                                <Card.Title>ECHO (<span className={"text-danger"}>E</span>ngine <span className={"text-danger"}>C</span>ombigem researc<span className={"text-danger"}>H</span> t<span className={"text-danger"}>O</span>ol)</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>ECHO is a tool to help visualizing CombiGEM data into interactive charts and graphs.</Card.Text>
                                <Card.Text>You may need to <span className={"text-danger"}><b>re-login with same credentials</b></span> as enginebio.io.</Card.Text>
                                <Button size="lg" href={echoURL} target={"_blank"} className="float-end">Go</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card border="secondary" className={"bg-light"}>
                            <Card.Header>
                                <Card.Title>TCGA Mutation Frequency</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>TCGA Mutation Frequency is a visualization tool for TCGA patient sample mutation data across 33 different cancer types. The tool visualizes relevant mutation type frequencies for a given gene per cancer type in a stacked bar plot. The data is also visualized in table format.</Card.Text>
                                <Card.Text>You may need to <span className={"text-danger"}><b>re-login with same credentials</b></span> as enginebio.io.</Card.Text>
                                <Button size="lg" href={tcgaMutFreqURL} target={"_blank"} className="float-end">Go</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card border="secondary" className={"bg-light"}>
                            <Card.Header>
                                <Card.Title>FUEL</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>FUEL (Foundation Unit for Engine's exceL) is a web application that summarizes knowledge of Engine's current and potential SL pairs.</Card.Text>
                                <Card.Text>You may need to <span className={"text-danger"}><b>re-login with same credentials</b></span> as enginebio.io.</Card.Text>
                                <Button size="lg" href={fuelUrl} target={"_blank"} className="float-end">Go</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>;
    }
}