import {API, Logger} from "aws-amplify";
import React from "react";
import {Alert, Badge, Button, ButtonGroup, Col, Row, Spinner} from "react-bootstrap";
import {ArrowClockwise, CheckCircleFill, CloudDownload, TrashFill, XCircleFill} from "react-bootstrap-icons";
import BootstrapTable, {ColumnDescription} from "react-bootstrap-table-next";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {DismissibleAlert} from "../Alerts/DismissibleAlert";
import '../CommonCss/TableSmall.css'
import {ReactBootrapTableSorting} from "../CommonFunc/ReactBootrapTableSorting";
import {ApiCallStatus, BUCKET_NAME, synergyFinderConsts, SynergyFinderPipelineStatus} from "../SynergyFinder/Const";
import {BatchPipelineRequest} from "./SynergyFinderInputForm";
import {DownloadButton} from "./DownloadButton";
import {USER_EMAIL} from "../Auth/LoginUser";

const logger = new Logger("SynergyFinderHistoryTable.tsx")

interface SynergyFinderHistoryTableProps {
    saveRowDataInParentState(selectedRows: Pipeline[]): void
    refreshToken: string
    showErrorAlert(message: string): void
}

interface SynergyFinderHistoryTableState {
    columns: ColumnDescription[]
    selectedRows: Pipeline[]
    rows: Pipeline[]
    fetchingStatus: ApiCallStatus
    fetchingErrorMsg: string
    deleteStatus: ApiCallStatus
    deleteErrorMsg: string
    downloadErrorMessage: string
}

export interface Pipeline extends BatchPipelineRequest {
    batchPipelineRequestUuid: string,
    drug1: string,
    drug2: string,
    geneStatus: string
    cellLine: string,
    s3KeyOutputGraphImage: string,
    s3KeyOutputGraphImageSmall: string,
    s3KeyOutputExcel: string,
    deleted: boolean,
}

export class SynergyFinderHistoryTable extends React.Component<SynergyFinderHistoryTableProps, SynergyFinderHistoryTableState> {
    constructor(props: SynergyFinderHistoryTableProps) {
        super(props);
        this.state = {
            fetchingStatus: ApiCallStatus.Completed,
            fetchingErrorMsg: "",
            deleteStatus: ApiCallStatus.Completed,
            deleteErrorMsg: "",
            selectedRows: [],
            downloadErrorMessage: "",
            columns: [
                {
                    dataField: "description",
                    text: "Description",
                    formatter: (col, row) => {
                        return <span style={{display: 'block', width: 450, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{col}</span>
                    },
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                },
                {
                    dataField: "calculationStatus",
                    text: "Progress",
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                        switch (cell) {
                            case SynergyFinderPipelineStatus.IN_PROGRESS:
                                return (<Spinner animation="border" variant="primary" size={"sm"}/>)
                            case SynergyFinderPipelineStatus.COMPLETED:
                                return (<CheckCircleFill color={"rgba(var(--bs-success-rgb),var(--bs-bg-opacity))"}/>)
                            case SynergyFinderPipelineStatus.FAILED:
                                return (<XCircleFill color={"rgba(var(--bs-danger-rgb),var(--bs-bg-opacity))"}/>)
                            default:
                                return (<Badge bg="secondary">{cell}</Badge>)
                        }
                    },
                },
                {
                    dataField: "geneStatus",
                    text: "Gene Status",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                        switch (cell) {
                            case SynergyFinderPipelineStatus.IN_PROGRESS:
                                return (<Spinner animation="border" variant="primary" size={"sm"}/>)
                            default:
                                return cell
                        }
                    },
                    filter: textFilter({
                        placeholder: "Search"
                    }),
                },
                {
                    dataField: "drug1",
                    text: "Drug 1",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                        switch (cell) {
                            case SynergyFinderPipelineStatus.IN_PROGRESS:
                                return (<Spinner animation="border" variant="primary" size={"sm"}/>)
                            default:
                                return cell
                        }
                    },
                    filter: textFilter({
                        placeholder: "Search"
                    }),
                },
                {
                    dataField: "drug2",
                    text: "Drug 2",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    filter: textFilter({
                        placeholder: "Search"
                    }),
                },
                {
                    dataField: "cellLine",
                    text: "Cell line",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    filter: textFilter({
                        placeholder: "Search"
                    }),
                },
                {
                    dataField: "normalization",
                    text: "Normalization",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                        switch (cell) {
                            case false:
                                return "No"
                            case true:
                                return "Yes"
                            default:
                                return "Unknown"
                        }
                    },
                },
                {
                    dataField: "calculationMethod",
                    text: "Calculation Method",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                },
                {dataField: "uuid", text: "Job ID", hidden: true},
                {
                    dataField: "uploadTimestamp",
                    text: "Date",
                    formatter: cell => {
                        if (!cell) {
                            return "";
                        }
                        return new Date(cell).toISOString().split('T')[0];
                    },
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                },
                {
                    dataField: "owner",
                    text: "Owner",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    filter: textFilter({
                        placeholder: "Search"
                    }),
                },
            ],
            rows: [],
        }
    }

    componentDidMount() {
        this.listAllPipelines()
    }

    setDownloadErrorMessage(msg: string) {
        this.setState({
            downloadErrorMessage: msg
        })
    }

    deletePipelines() {
        if (window.confirm("Delete job, are you sure?")) {
            for (let row of this.state.selectedRows) {
                if (row.owner !== USER_EMAIL) {
                    this.setState({
                        deleteStatus: ApiCallStatus.Error,
                        deleteErrorMsg: "Please check with " + row.owner + " before deleting \"" + row.description + "\""
                    })
                    return
                }
            }
            let selectedUUIDs: string[] = this.state.selectedRows.map(row => row.uuid)
            console.log("selectedUUIDs = ", selectedUUIDs)
            // TODO - add usage monitoring.
            this.setState({
                deleteStatus: ApiCallStatus.Loading,
                deleteErrorMsg: ""
            }, () => {
                // TODO - API call to delete job that is loading may not work, since pipeline uuid = "", only batch_request_uuid exists in DB
                API.del(synergyFinderConsts.API_NAME, "/pipeline", {
                    queryStringParameters: {
                        uuids: selectedUUIDs.join(",")
                    },
                })
                    .then((response: any) => {
                        this.setState({
                            deleteStatus: ApiCallStatus.Completed,
                            deleteErrorMsg: ""
                        })
                        this.refreshTableAndReport()
                    })
                    .catch(err => {
                        this.setState({
                            deleteStatus: ApiCallStatus.Error,
                            deleteErrorMsg: err + "",
                        })
                    })
            })
        }
    }

    listAllPipelines() {
        this.setState({
            fetchingStatus: ApiCallStatus.Loading
        }, () => {
            API.get(synergyFinderConsts.API_NAME, "/pipeline", {})
                .then((response: any) => {
                    logger.info("Response from API - ", response)
                    this.setState({
                        fetchingStatus: ApiCallStatus.Completed,
                        fetchingErrorMsg: "",
                        // Need to convert
                        rows: response.data
                    })
                })
                .catch((err: any) => {
                    this.setState({
                        fetchingStatus: ApiCallStatus.Error,
                        fetchingErrorMsg: err + "",
                    })
                })
        })

    }

    refreshTableAndReport() {
        this.listAllPipelines()
        // Also reset report to display nothing
        this.setState({
            selectedRows: []
        }, () => this.props.saveRowDataInParentState(this.state.selectedRows))
    }

    componentDidUpdate(prevProps: Readonly<SynergyFinderHistoryTableProps>, prevState: Readonly<SynergyFinderHistoryTableState>, snapshot?: any) {
        if (this.props.refreshToken !== prevProps.refreshToken) {
            logger.info("Refresh token changed, refresh SynergyFinderHistoryTable")
            this.refreshTableAndReport()
        }
    }

    mergeRows(clickedRows: Pipeline[], isSelect: boolean) {
        console.log("mergeRows, clickedRows = ", clickedRows, " isSelect = ", isSelect)

        let result: Pipeline[] = JSON.parse(JSON.stringify(this.state.selectedRows))
        let rowsExist: string[] = []
        for (let i = 0; i < this.state.selectedRows.length; i++) {
            for (let clickedRow of clickedRows) {
                if (this.state.selectedRows[i].uuid === clickedRow.uuid) {
                    rowsExist.push(clickedRow.uuid)
                    if (!isSelect) {
                        console.log("mergeRows, toRemove = ", clickedRow, " isSelect = ", isSelect)
                        let resultWithClickedRowRemoved: Pipeline[] = []
                        for (let j = 0; j < result.length; j++) {
                            if (result[j].uuid !== clickedRow.uuid) {
                                resultWithClickedRowRemoved.push(result[j])
                            }
                        }
                        result = resultWithClickedRowRemoved
                    }
                }
            }
        }

        if (isSelect) {
            for (let clickedRow of clickedRows) {
                if (!(clickedRow.uuid in rowsExist)) {
                    result.push(clickedRow)
                }
            }
        }

        console.log("final result after merged rows = ", result)
        this.setState({
            selectedRows: result
        }, () => {
            this.props.saveRowDataInParentState(this.state.selectedRows)
        })
        return
    }

    render() {
        let tableRendering = <ToolkitProvider
            keyField={"uuid"}
            data={this.state.rows}
            columns={this.state.columns}
            search
        >
            {
                props => (
                    <>
                        <Row>
                            <Col md={4}>
                                <Search.SearchBar placeholder={"Search anything"} {...props.searchProps} />
                            </Col>
                            <Col md={8}>
                                {
                                    this.state.selectedRows.length > 0 &&
                                    <Alert className={"float-end"} variant={"info"}>
                                        <Badge pill bg={"success"}>{this.state.selectedRows.length}</Badge> selected reports for
                                        <ButtonGroup size={"sm"}>
                                            <DownloadButton
                                                className={"ms-2"}
                                                s3Bucket={BUCKET_NAME}
                                                s3Keys={this.state.selectedRows.map(row => row.s3KeyOutputExcel)}
                                                icon={<span><CloudDownload /> Batch download</span>}
                                                variant={"success"}
                                                buttonSize={"sm"}
                                                setErrorMessage={this.setDownloadErrorMessage.bind(this)}
                                            />
                                            <Button
                                                size={"sm"}
                                                variant={"danger"}
                                                onClick={this.deletePipelines.bind(this)}>
                                                {this.state.deleteStatus === ApiCallStatus.Loading ? <Spinner animation={"border"} variant={"light"} size={"sm"} /> : <span><TrashFill/> Batch delete</span>}
                                            </Button>
                                        </ButtonGroup>
                                    </Alert>
                                }
                            </Col>
                        </Row>
                        <Row className={"mt-2"}>
                            <BootstrapTable
                                bordered
                                hover
                                filter={filterFactory()}
                                noDataIndication={"No data found"}
                                wrapperClasses={"table-responsive"}
                                selectRow={{
                                    mode: 'checkbox',
                                    clickToSelect: true,
                                    onSelect: (row , isSelected, rowIndex, e) => {
                                        this.mergeRows([row], isSelected)
                                    },
                                    onSelectAll: (isSelect, rows) => {
                                        console.log("SelectAll = ", isSelect, " row = ", rows)
                                        this.mergeRows(rows, isSelect)
                                    }
                                }}
                                defaultSorted={[
                                    {
                                        dataField: "timestamp",
                                        order: "desc"
                                    }
                                ]}
                                pagination={
                                    paginationFactory({
                                        sizePerPage: 10,
                                        sizePerPageList: [10, 20, 40]
                                    })
                                }
                                {...props.baseProps}
                            />
                        </Row>
                    </>
                )
            }
        </ToolkitProvider>
        if (this.state.fetchingStatus === ApiCallStatus.Loading) {
            tableRendering = (<>
                <Spinner
                    animation="border"
                    size="sm"
                    variant="primary"
                >
                </Spinner>
                <span className="text-primary m-2 fw-bold">Loading all jobs ...</span>
            </>)
        }

        return (
            <>
                <Row>
                    <Col md={9}><h3>History</h3></Col>
                    <Col md={3}><Button className={"float-end"} variant={"primary"} onClick={this.refreshTableAndReport.bind(this)}><ArrowClockwise /></Button></Col>
                </Row>
                {tableRendering}
                {this.state.fetchingErrorMsg !== "" && <DismissibleAlert message={this.state.fetchingErrorMsg}/>}
                {this.state.deleteErrorMsg !== "" && <DismissibleAlert message={this.state.deleteErrorMsg} />}
                {this.state.downloadErrorMessage !== "" && <DismissibleAlert message={this.state.downloadErrorMessage} />}
            </>
        );
    }
}