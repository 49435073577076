import React from "react";
import {SearchBoxComparator} from "../Common/Enum";
import {SearchResultTableWithUpdateUI} from "../Common/SearchResultTableWithUpdateUI";
import {View} from "../GenGenPlate/Enum";
import {gppApiConsts} from "./GppApiConsts";
import {GppTableDataApi} from "./GppTableDataApi";

interface CellLineSearchResultTableState {}

interface CellLineSearchResultTableProps {
    keywords: string
    comparator: SearchBoxComparator
    refreshToken: string
}

export class CellLineSearchResultTable extends React.Component<CellLineSearchResultTableProps, CellLineSearchResultTableState> {
    render() {
        // TODO after prototype - consider remove this table, instead improve error message to say "Did you means NCIBL128 instead of NCIBL_128"
        return <SearchResultTableWithUpdateUI
            refreshToken={this.props.refreshToken}
            databaseName={gppApiConsts.DATABASE_NAME}
            tableName={"cell_line"}
            keywords={this.props.keywords}
            columns={""}
            comparator={this.props.comparator}
            view={View.Read}
            keyField={"id"}
            title={"Cell line"}
            modalForm={React.Component} // Empty React component class since cell line is read-only table and never need modal.
            legends={<></>}
            rowClasses={(row, index) => "table-light"}
            columnsHidden={[]}
            columnsDisplayInOrder={["cellLine", "depmapId", "cancerType", "cancerSubtype"]}
            searchTable={GppTableDataApi.searchTable}
        />
    }
}