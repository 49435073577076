import {Auth} from "@aws-amplify/auth";

export let USER_NAME: string = ""
export let USER_EMAIL: string = ""

export function getUsername() {
    Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
        console.log("USER_NAME = " + user.attributes.email.split("@")[0])
        USER_NAME = user.attributes.email.split("@")[0]
        USER_EMAIL = user.attributes.email
    }).catch(err => {
        console.log(err)
    });
}