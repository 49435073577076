import React from "react";
import {UpdateSearchUI} from "./UpdateSearchUI";

interface HistoryProps {}

interface HistoryState {}

export class History extends React.Component<HistoryProps, HistoryState> {
    render() {
        return <UpdateSearchUI
            searchTitle={"Search by user, table, or row ID:"}
            table={"update_history"} />;
    }
}