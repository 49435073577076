import {View} from "@aws-amplify/ui-react";
import React from "react";
import {Alert} from "react-bootstrap";

const notification = ""
let displayEverywhere = notification ? true : false

interface SystemNotificationState {
    display: boolean
}

interface SystemNotificationProps {
    padding: any
    style: any
}

export class SystemNotification extends React.Component<SystemNotificationProps, SystemNotificationState> {
    public static defaultProps = {
        padding: 0,
        style: {}
    }

    constructor(props: SystemNotificationProps) {
        super(props);
        this.state = {
            display: displayEverywhere
        }
    }

    closeEverywhere() {
        this.setState({display: false})
        displayEverywhere = false
    }

    render() {
        return displayEverywhere &&
            <View padding={this.props.padding} style={this.props.style} size>
                <Alert variant={"warning"}
                       dismissible
                       onClose={this.closeEverywhere.bind(this)}>
                    <Alert.Heading>Warning!</Alert.Heading>
                    {notification}
                </Alert>
            </View>
    }
}