import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {v4 as uuidv4} from 'uuid';
import {DismissibleAlert} from "../Alerts/DismissibleAlert";
import "../CommonCss/Container.css"
import {Pipeline, SynergyFinderHistoryTable} from "./SynergyFinderHistoryTable";
import {SynergyFinderInputForm} from "./SynergyFinderInputForm";
import {SynergyFinderReport} from "./SynergyFinderReport";

interface SynergyFinderState {
    selectedReports: Pipeline[]
    synergyFinderHistoryTable: {
        refreshToken: string
    }
    errorMessage: string
}

export class SynergyFinder extends React.Component<any, SynergyFinderState> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedReports: [],
            synergyFinderHistoryTable: {
                refreshToken: uuidv4()
            },
            errorMessage: ""
        }
    }

    refreshHistoryTable() {
        this.setState({
            synergyFinderHistoryTable: {
                refreshToken: uuidv4()
            }
        })
    }

    showErrorAlert(message: string) {
        this.setState({
            errorMessage: message
        })
    }

    saveRowData(selectedReports: Pipeline[]) {
        console.log("Inside SynergyFinder.saveRowData, selectedReports = ", selectedReports)
        this.setState({
            selectedReports: selectedReports
        })
    }

    render() {
        return (
            <>
                <DismissibleAlert message={this.state.errorMessage}/>
                <Container className="p-4 bg-light wide-container">
                    <Row>
                        <Col className="col-3">
                            <SynergyFinderInputForm
                                refreshHistoryTable={this.refreshHistoryTable.bind(this)}
                            />
                        </Col>
                        <Col className="col-9">
                            <SynergyFinderHistoryTable
                                saveRowDataInParentState={this.saveRowData.bind(this)}
                                refreshToken={this.state.synergyFinderHistoryTable.refreshToken}
                                showErrorAlert={this.showErrorAlert.bind(this)}
                            />
                        </Col>
                    </Row>
                </Container>

                <SynergyFinderReport
                    selectedReports={this.state.selectedReports}
                    refreshHistoryTable={this.refreshHistoryTable.bind(this)}
                />
                {/*//
                    Using React "key" to create new SynergyFinderReport.
                    https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
                    --> CANNOT because we need to pass reportIndex to SynergyFinderReport component, and key is not an accessible props
                */}
            </>
        );
    }
}