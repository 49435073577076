import {API, Logger} from "aws-amplify";
import {USER_EMAIL} from "../Auth/LoginUser";
import {commonApiConsts} from "./Const";
import {EventType, FrontendModule} from "./Enum";
import {v4 as uuidv4} from "uuid";

const logger = new Logger("UsageTracking.ts")

export interface UsageTrackingEvent {
    uuid?: string
    module: FrontendModule
    event: EventType
    table: string
    database: string
    user?: string
    keywords?: string // Comma seperated
    timestamp?: Date
    extraMetadata?: Record<string, string>
    filename?: string
}

let usageTrackingEventSendingQueue: UsageTrackingEvent[] = []
let usageTrackingEventPendingQueue: UsageTrackingEvent[] = []
let retryRecord: Record<string, number> = {}
setInterval(sendToBackEnd, 10000)

function sendToBackEnd() {
    if (usageTrackingEventPendingQueue.length === 0) {
        console.log("No usage tracking event in the queue")
        return
    }

    while (usageTrackingEventPendingQueue.length > 0) {
        let event: UsageTrackingEvent | undefined = usageTrackingEventPendingQueue.pop()
        if (event !== undefined) {
            usageTrackingEventSendingQueue.push(event)
        }
    }

    API.post(
        commonApiConsts.API_NAME,
        "/usage-tracking",
        {
            body: {
                data: usageTrackingEventSendingQueue
            },
        })
        .then(response => {
            usageTrackingEventSendingQueue = []
        }).catch(err => {
            // Pop events back to pending queue
            logger.error(err)
            while (usageTrackingEventSendingQueue.length > 0) {
                let event: UsageTrackingEvent | undefined = usageTrackingEventSendingQueue.pop()

                if (event !== undefined && event.uuid !== undefined) {
                    if (retryRecord[event.uuid] === undefined) {
                        retryRecord[event.uuid] = 1
                    }
                    if (retryRecord[event.uuid] > 5) {
                        // Stop retry after 5 times
                        continue
                    }
                    if (retryRecord[event.uuid]) {
                        retryRecord[event.uuid] = retryRecord[event.uuid] + 1
                        usageTrackingEventPendingQueue.push(event)
                    }
                }
            }
        }).finally(() => {

        })
}

export class UsageTracking {
    static recordEvent(event: UsageTrackingEvent) {
        event.uuid = uuidv4()
        event.user = USER_EMAIL
        event.timestamp = new Date()
        usageTrackingEventPendingQueue.push(event)
    }
}

