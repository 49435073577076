import React from "react";
import {Container, Spinner} from "react-bootstrap";
import {SangerQCReportPieChart} from "./SangerQCReportPieChart";
import {SangerQCReportTable} from "./SangerQCReportTable";
import {API, Logger} from "aws-amplify";
import {SangerQCHistoryTableRow} from "./SangerQCHistoryTable";
import {sangerQcApiConsts, SangerQCPipeline} from "./Const";
import {SangerQCReportHeader} from "./SangerQCReportHeader";
import {v4 as uuidv4} from "uuid";
import {DismissibleAlert} from "../Alerts/DismissibleAlert";

interface SangerQCReportProps {
    reportIndex: string
    show: boolean
    hideSangerQCReport(): any
    refreshHistoryTable(): void
}

interface SangerQCReportState {
    reportMetadata: SangerQCHistoryTableRow
    loading: boolean
    pieChartRefreshToken: string
    reportTableRefreshToken: string
    errorMessage: string
}

const defaultState: SangerQCReportState = {
    reportMetadata: {
        uuid: "",
        description: "",
        userName: "",
        stage: "",
        slNumber: "",
        timestamp: "",
        status: "",
        errorMessage: "",
        s3URL: "",
        batchJobURL: "",
        deleted: false,
    },
    loading: false,
    pieChartRefreshToken: "",
    reportTableRefreshToken: "",
    errorMessage: "",
}

const logger = new Logger("SangerQCReport")

export class SangerQCReport extends React.Component<SangerQCReportProps, SangerQCReportState> {
    constructor(props: SangerQCReportProps) {
        super(props)
        this.state = defaultState
    }

    refreshInterval: any

    componentDidUpdate(prevProps: Readonly<SangerQCReportProps>, prevState: Readonly<SangerQCReportState>, snapshot?: any) {
        if (this.props.reportIndex !== prevProps.reportIndex
            && this.props.reportIndex !== null
            && this.props.reportIndex !== "") {
            this.getReportMetadata(this.props.reportIndex)
        }

        // Turn-off refresh if props.show is false and reset state to default
        // This is to fix bug where we tried to delete pipeline.
        if (!this.props.show && this.state.reportMetadata.uuid !== defaultState.reportMetadata.uuid) {
            this.setState(defaultState)
            clearInterval(this.refreshInterval)
        }
    }

    componentWillUnmount() {
        logger.debug("componentWillUnmount()")
        clearInterval(this.refreshInterval)
    }

    getReportMetadata(reportIndex: string) {
        logger.debug("SangerQCReport - getReportMetadata")
        this.setState({
            loading: true
        })
        let request = {
            headers: {},
            response: true,
            queryStringParameters: {
                "uuid": reportIndex,
            }
        }
        API.get(sangerQcApiConsts.API_NAME, sangerQcApiConsts.Path.BASE, request).then(response => {
            logger.debug("Response from API - " + response)
            this.setState({
                reportMetadata: {
                    uuid: response.data.uuid,
                    description: response.data.description,
                    userName: response.data.userName,
                    stage: response.data.stage,
                    slNumber: response.data.slNumber,
                    timestamp: response.data.timestamp,
                    status: response.data.status,
                    errorMessage: response.data.errorMessage,
                    s3URL: response.data.s3URL,
                    batchJobURL: response.data.batchJobURL,
                    deleted: response.data.deleted,
                }
            }, () => {
                // This must run here because refresh function should only trigger once the state get updated.
                // Only run if status is still in progress
                if (response.data.status.toLowerCase() === SangerQCPipeline.Status.IN_PROGRESS.toLowerCase()) {
                    this.refreshInterval = setInterval(
                        () => this.refreshReportStatus(reportIndex),
                        5000
                    )
                }
            })
        }).catch(err => {
            // Do not turn on error alert, as temporary network issue may happen
            // and should be auto-recovered in next interval
            // this.showErrorAlert(errorMessage(err))
            logger.error("Error from API " + err)
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    refreshReportStatus(reportIndex: string) {
        if (this.state.reportMetadata.status.toLowerCase() !== SangerQCPipeline.Status.IN_PROGRESS.toLowerCase()
            // Prevent this function from running after pipeline deletion.
            || this.state.reportMetadata.deleted) {
            return
        }

        logger.debug("Refresh report metadata ...")
        let request = {
            headers: {},
            response: true,
            queryStringParameters: {
                "uuid": reportIndex,
            }
        }
        API
            .get(sangerQcApiConsts.API_NAME, sangerQcApiConsts.Path.BASE, request)
            .then(response => {
                // Do not update state without checking status.
                // Because updating state may lead to re-rendering of child component (SangerQCReportHeader, etc.)
                // Lead to child component having null state, since report result is not exist in DynamoDB yet.
                if (response.data.status.toLowerCase() === SangerQCPipeline.Status.IN_PROGRESS.toLowerCase()) {
                    return
                }

                // Clear interval check after status become completed or failed
                clearInterval(this.refreshInterval)

                let reportMetadata = this.state.reportMetadata
                reportMetadata.status = response.data.status
                reportMetadata.errorMessage = response.data.errorMessage
                reportMetadata.deleted = response.data.deleted
                this.setState({
                    reportMetadata: reportMetadata,
                    // To trigger re-render of pieChart & CSV table, while uuid still the same
                    pieChartRefreshToken: uuidv4(),
                    reportTableRefreshToken: uuidv4()
                })

                // Refresh history table also
                this.props.refreshHistoryTable()
            })
            .catch(err => {
                logger.error("Error from API " + err)
                // Don't clear interval, because error can be 404 when report not added to DB yet.
                // clearInterval(this.refreshInterval)
            })
    }

    showErrorAlert(message: string) {
        this.setState({
            errorMessage: message
        })
    }

    render() {
        if (this.state === null || this.state.reportMetadata === null) {
            return (<></>)
        }

        if (this.state.loading) {
            return (
                <>
                    <hr />
                    <Container className="p-4 bg-light" style={{ marginBottom: "1rem" }}>
                        <Spinner
                            animation="border"
                            size="sm"
                            variant="primary"
                        >
                        </Spinner>
                        <span className="text-primary m-2 fw-bold">Loading result ...</span>
                    </Container>
                </>)
        }

        if (this.props.show) {
            return (
                <>
                    <hr />
                    <DismissibleAlert message={this.state.errorMessage} />
                    <Container className="p-4 bg-light" style={{ marginBottom: "1rem" }}>
                        <h3 style={{ marginBottom: 20 }}>Result</h3>
                        <SangerQCReportHeader
                            showErrorAlert={this.showErrorAlert.bind(this)}
                            hideSangerQCReport={this.props.hideSangerQCReport}
                            reportMetadata={this.state.reportMetadata}
                            refreshHistoryTable={this.props.refreshHistoryTable} />
                        <SangerQCReportPieChart
                            showErrorAlert={this.showErrorAlert.bind(this)}
                            refreshToken={this.state.pieChartRefreshToken}
                            reportMetadata={this.state.reportMetadata} />
                        <SangerQCReportTable
                            showErrorAlert={this.showErrorAlert.bind(this)}
                            refreshToken={this.state.reportTableRefreshToken}
                            reportMetadata={this.state.reportMetadata} />
                    </Container>
                </>
            )
        } else {
            return (<></>)
        }
    }
}