export let currentEnv = getCurrentEnv()
export let shortenEnv: String = getShortenEnv()

// Switch to awsconfigDev new user pool with TOTP here.
export const awsconfigDev = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "oauth": {},

    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_user_files_s3_bucket": "dev-sanger-qc-pipeline-input",

    "aws_cognito_identity_pool_id": "us-east-1:f5ed8565-40e9-4ae5-b257-e278fcb3af63",
    "aws_user_pools_id": "us-east-1_Tcgoj43RF",
    "aws_user_pools_web_client_id": "50rqkarfirf7grpe29d4m3b79h",
}

export const awsconfigProd = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "oauth": {},

    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_user_files_s3_bucket": "prod-sanger-qc-pipeline-input",

    "aws_cognito_identity_pool_id": "us-east-1:08b90972-2410-42fd-ab97-a2241f6a2c39",
    "aws_user_pools_id": "us-east-1_dytodYM4M",
    "aws_user_pools_web_client_id": "6fbqeebq9soogenupoe3ocphds",
}


export const awsconfig = currentEnv === "production" ? awsconfigProd : awsconfigDev
export const authConfig = {
    identityPoolId: awsconfig.aws_cognito_identity_pool_id,
    region: awsconfig.aws_cognito_region,
    userPoolId: awsconfig.aws_user_pools_id,
    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
    mandatorySignIn: true,
}
export const bucketName = awsconfig.aws_user_files_s3_bucket


function getShortenEnv(): String {
    console.log("Host is " + window.location.host)
    if (window.location.host === "enginebio.io") {
        return "prod"
    }
    return "dev"
}

function getCurrentEnv(): String {
    console.log("Host is " + window.location.host)
    if (window.location.host === "enginebio.io") {
        return "production"
    }
    return "develop"
}