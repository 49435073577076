import {currentEnv} from "../../Const";

export const SangerQCPipeline = {
    Status: {
        IN_PROGRESS: "In progress",
        FAILED: "Failed",
        COMPLETED: "Completed",
    }
}

export const sangerQcApiConsts = {
    API_NAME: "sangerqcpipelines",
    API_ENDPOINT: currentEnv === "develop" ? "https://en390iuwd7.execute-api.us-east-1.amazonaws.com/develop" : "https://k1c6xa1z8k.execute-api.us-east-1.amazonaws.com/production",
    Path: {
        LIST_ALL: "/sangerqcpipelines/all",
        BASE: "/sangerqcpipelines",
        REPORT: "/sangerqcpipelines/result",
    }
}