import {DEFAULT_PLATE_MAP} from "./PlateMap/GenGenPlateMapPlasmid";

export class PlateRow {
    0: string = 'A'
    A: string = ""
    B: string = ""
    C: string = ""
    D: string = ""
    E: string = ""
    F: string = ""
    G: string = ""
    H: string = ""
    I: string = ""
    J: string = ""
    K: string = ""
    L: string = ""

    static convertPlateMapRowListToStr(list: PlateRow[]): string {
        let result = ""
        for (let i = 0; i<list.length; i++) {
            let row: PlateRow = list[i]
            result = result + row.A + " " + row.B + " " + row.C + " " + row.D + " " + row.E + " " + row.F + " " +
                row.G + " " + row.H + " " + row.I + " " + row.J + " " + row.K + " " + row.L
            if (i < list.length - 1) {
                result = result + `\n`
            }
        }
        return result
    }

    static convertStrToPlateMapRowList(plateDataStr: string | undefined): PlateRow[] {
        let result: PlateRow[] = []
        console.log("plateDataStr = ", plateDataStr)
        if (plateDataStr === undefined || plateDataStr === null || plateDataStr.trim() === "") {
            plateDataStr = DEFAULT_PLATE_MAP
        }
        // TODO after prototype - add some sanity check here - length is not 8 * 12, funny character, etc.
        let rows: string[] = plateDataStr.split(/[\r?\n]+/) // This to cover newline in both Window and Linux-based OS.
        for (let i = 0; i <= 7; i++) {
            if (i < rows.length) {
                let cells = rows[i].trim().split(/\s+/)
                result.push({
                    // First row - "0" shouldn't use A,B,C instead of 1, 2, 3
                    0: String.fromCharCode('A'.charCodeAt(0) + i),
                    A: cells[0] && cells[0].trim() !== "" ? cells[0]: "empty",
                    B: cells[1] && cells[1].trim() !== "" ? cells[1]: "empty",
                    C: cells[2] && cells[2].trim() !== "" ? cells[2]: "empty",
                    D: cells[3] && cells[3].trim() !== "" ? cells[3]: "empty",
                    E: cells[4] && cells[4].trim() !== "" ? cells[4]: "empty",
                    F: cells[5] && cells[5].trim() !== "" ? cells[5]: "empty",
                    G: cells[6] && cells[6].trim() !== "" ? cells[6]: "empty",
                    H: cells[7] && cells[7].trim() !== "" ? cells[7]: "empty",
                    I: cells[8] && cells[8].trim() !== "" ? cells[8]: "empty",
                    J: cells[9] && cells[9].trim() !== "" ? cells[9]: "empty",
                    K: cells[10] && cells[10].trim() !== "" ? cells[10]: "empty",
                    L: cells[11] && cells[11].trim() !== "" ? cells[11]: "empty",
                })
            } else {
                result.push({
                    0: String.fromCharCode('A'.charCodeAt(0) + i),
                    A: "empty",
                    B: "empty",
                    C: "empty",
                    D: "empty",
                    E: "empty",
                    F: "empty",
                    G: "empty",
                    H: "empty",
                    I: "empty",
                    J: "empty",
                    K: "empty",
                    L: "empty",
                })
            }
        }
        return result
    }
}

