import {API} from "aws-amplify";
import React from "react";
import {Alert, Card} from "react-bootstrap";
import {ApiCallStatus} from "../../Common/Enum";
import {DeliveryMethod, View} from "../Enum";
import {genGenPlateApiConst} from "../GenGenPlateApiConst";
import {GenGenPlateMetadataForm} from "../Metadata/GenGenPlateMetadataForm";
import {GenGenPlateMapForm} from "../PlateMap/GenGenPlateMapForm";
import {GenGenPlateRawDataForm} from "../RawData/GenGenPlateRawDataForm";
import {
    GenGenPlateAggregatedGetResponse,
    GenGenPlateAggregatedTableRow
} from "./GenGenPlateAggregatedSearchResultTable";

interface GenGenPlateAggregatedFormProps {
    displayHeader: boolean
    autofillOwnerByLogInId: boolean
    initialValues: GenGenPlateAggregatedTableRow
    view: View // Have to specify to simplify code in RowDataModal.tsx
}

interface GenGenPlateAggregatedFormState {
    plateId: string
    genGenPlateAggregated: GenGenPlateAggregatedGetResponse
    getGenGenPlateAggregatedStatus: ApiCallStatus
    getGenGenPlateAggregatedMessage: string
}

export class GenGenPlateAggregatedForm extends React.Component<GenGenPlateAggregatedFormProps, GenGenPlateAggregatedFormState> {
    constructor(props: GenGenPlateAggregatedFormProps) {
        super(props);
        this.state = {
            plateId: props.initialValues.plateId,
            genGenPlateAggregated: {
                metadata: {
                    dotmaticsExperimentId: "",
                    replicateNumber: 0,
                    plateId: "",
                    cellLine: "",
                    experimentStartDate: "",
                    project: "",
                    experimentDurationInDays: 0,
                    geneAs: "",
                    geneBs: "",
                    owner: "",
                    additionalNote: "",
                    deliveryMethod: DeliveryMethod.Lentivirus,
                    downloadLink: "",
                    lastModified: "",
                },
                plateMap: {
                    plateId: "",
                    plateMap: "",
                    owner: "",
                },
                rawData: []
            },
            getGenGenPlateAggregatedStatus: ApiCallStatus.NoData,
            getGenGenPlateAggregatedMessage: "",
        }
    }

    componentDidMount() {
        this.getAggregatedData()
    }

    componentDidUpdate(prevProps: Readonly<GenGenPlateAggregatedFormProps>, prevState: Readonly<GenGenPlateAggregatedFormState>, snapshot?: any) {
        if (this.props.initialValues.plateId !== prevProps.initialValues.plateId) {
            this.setState({
                plateId: this.props.initialValues.plateId
            }, this.getAggregatedData)
        }
    }

    getAggregatedData() {
        this.setState({
            getGenGenPlateAggregatedStatus: ApiCallStatus.Loading,
            getGenGenPlateAggregatedMessage: ""
        }, () => {
            API.post(
                genGenPlateApiConst.API_NAME,
                genGenPlateApiConst.path.AGGREGATED,
                {
                    body: {
                        plateId: this.state.plateId,
                        keywords: "",
                    },
                }).then(response => {
                    this.setState({
                        genGenPlateAggregated: response["data"][0],
                        getGenGenPlateAggregatedStatus: ApiCallStatus.Completed,
                        getGenGenPlateAggregatedMessage: ""
                    })
                }).catch(err => {
                    this.setState({
                        getGenGenPlateAggregatedStatus: ApiCallStatus.Error,
                        getGenGenPlateAggregatedMessage: err.toString(),
                    })
                })
        })
    }
    
    render() {
        return <Card>
            {this.props.displayHeader && <Card.Header>Gen gen plate aggregated read-only view</Card.Header>}
            <Card.Body>
                {this.state.getGenGenPlateAggregatedStatus === ApiCallStatus.Error &&
                    <Alert variant={"danger"}>{this.state.getGenGenPlateAggregatedMessage}</Alert> }
                {this.state.getGenGenPlateAggregatedStatus === ApiCallStatus.Loading &&
                    <p className={"text-primary"}>Loading ...</p> }
                {this.state.getGenGenPlateAggregatedStatus === ApiCallStatus.Completed &&
                    <>
                        <GenGenPlateMetadataForm
                            displayHeader={false}
                            view={View.Read}
                            autofillOwnerByLogInId={false}
                            initialValues={this.state.genGenPlateAggregated.metadata}/>
                        <hr/>
                        <GenGenPlateMapForm
                            displayHeader={false}
                            view={View.Read}
                            autofillOwnerByLogInId={false}
                            initialValues={this.state.genGenPlateAggregated.plateMap} />
                        {
                            this.state.genGenPlateAggregated.rawData && this.state.genGenPlateAggregated.rawData.map((item, i) => <>
                                <hr/>
                                <GenGenPlateRawDataForm
                                        displayHeader={false}
                                        view={View.Read}
                                        initialValues={item} />
                            </>)
                        }
                    </>
                }
            </Card.Body>
        </Card>;
    }
}