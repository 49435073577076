import React from "react";
import {SangerQCHistoryTableRow} from "./SangerQCHistoryTable";
import {Alert, Table} from "react-bootstrap";
import {API, Logger} from "aws-amplify";
import {errorMessage} from "../Alerts/Const";
import {SangerQCPipeline, sangerQcApiConsts} from "./Const";

interface SangerQCReportHeaderProps {
    hideSangerQCReport(): any
    refreshHistoryTable(): void
    showErrorAlert(message: string): void
    reportMetadata: SangerQCHistoryTableRow
}

interface SangerQCReportHeaderState extends SangerQCHistoryTableRow {
}

const logger = new Logger("SangerQCReportHeader.tsx")

export class SangerQCReportHeader extends React.Component<SangerQCReportHeaderProps, SangerQCReportHeaderState> {
    deleteReport(reportIndex: string) {
        logger.info("execute delete API to DB to delete pipeline ID = " + reportIndex)
        let request = {
            headers: {},
            response: true,
            queryStringParameters: {
                "uuid": reportIndex,
            }
        }
        API.del(sangerQcApiConsts.API_NAME, sangerQcApiConsts.Path.BASE, request).then(response => {
            logger.info("Response from API - " + response)
            // Trigger a refresh to the history table.
            this.props.refreshHistoryTable()
        }).catch(err => {
            // Notify error
            logger.info("Error from API " + err)
            this.props.showErrorAlert(errorMessage(err))
        })
    }

    render() {
        logger.info("SangerQCReportHeader - rendering components")

        // Show status based on color code.
        let statusCell = <></>
        switch (this.props.reportMetadata.status.toLowerCase()) {
            case SangerQCPipeline.Status.IN_PROGRESS.toLowerCase():
                statusCell = (<td className="text-primary fw-bold">{this.props.reportMetadata.status}</td>)
                break
            case SangerQCPipeline.Status.COMPLETED.toLowerCase():
                statusCell = <td className="text-success fw-bold">{this.props.reportMetadata.status}</td>
                break
            case SangerQCPipeline.Status.FAILED.toLowerCase():
                statusCell = <td className="text-danger fw-bold">{this.props.reportMetadata.status}</td>
                break
            default:
                statusCell = <td className="table-dark fw-bold">{this.props.reportMetadata.status}</td>
        }

        let infoAlert = (<></>)
        if (this.props.reportMetadata.status.toLowerCase() === SangerQCPipeline.Status.IN_PROGRESS.toLowerCase()) {
            infoAlert = (
                <>
                    <Alert variant="primary">Report will be available below once status is <span className="text-success fw-bold">Completed</span>.</Alert>
                    <Alert variant="primary">It may take around 5 minutes.</Alert>
                    <Alert variant="primary">Refresh the page to get updated result.</Alert>
                </>)
        }

        return (
            <>
                <Table bordered striped>
                    <thead>
                    <tr>
                        <th>Description</th>
                        <th>User</th>
                        <th>SL</th>
                        <th>Library</th>
                        <th>Status</th>
                        <th>Timestamp</th>
                        <th>Actions</th>
                        <th>Report ID</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{this.props.reportMetadata.description}</td>
                        <td>{this.props.reportMetadata.userName}</td>
                        <td>{this.props.reportMetadata.slNumber}</td>
                        <td>{this.props.reportMetadata.stage}</td>
                        {statusCell}
                        <td>{new Date(this.props.reportMetadata.timestamp).toLocaleString()}</td>
                        <td>
                            <button
                                className="btn btn-sm btn-outline-danger" onClick={() => {
                                // eslint-disable-next-line no-restricted-globals
                                if (confirm("Delete this pipeline with ID " + this.props.reportMetadata.uuid + " ?")) {
                                    this.deleteReport(this.props.reportMetadata.uuid)
                                    this.props.hideSangerQCReport()
                                }
                            }}>Delete</button>
                        </td>
                        <td>{this.props.reportMetadata.uuid}</td>
                    </tr>
                    </tbody>
                </Table>
                {infoAlert}
            </>
        );
    }
}