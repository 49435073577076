import React from "react";
import {ButtonGroup, Col, Row, Spinner, Table, ToggleButton} from "react-bootstrap";
import {Cell, Pie, PieChart, Tooltip} from "recharts";
import {SangerQCHistoryTableRow} from "./SangerQCHistoryTable";
import {API, Logger} from "aws-amplify";
import {SangerQCPipeline, sangerQcApiConsts} from "./Const";

const COLORS = ["#bcd0c7", "#badce3", "#dfc2c4", "#e6dbb9", "#dfe0e1", "#cbccce", "#373b3e"]
const logger = new Logger("SangerQCReportPieChart")

interface SangerQCReportPieChartState {
    pieChartDataWithDR: {
        name: string
        value: number
    }[]
    TotalClone: number
    ICCount: number
    UCCount: number
    MCount: number
    MGCount: number
    NCCount: number
    OthersCount: number
    DRCount: number
    loading: boolean
    excludeDR: boolean
}

interface SangerQCReportPieChartProps {
    reportMetadata: SangerQCHistoryTableRow
    refreshToken: string
    showErrorAlert(message: string): void
}

export class SangerQCReportPieChart extends React.Component<SangerQCReportPieChartProps, SangerQCReportPieChartState> {
    constructor(props: SangerQCReportPieChartProps) {
        super(props);
        this.state = {
            TotalClone: 0,
            pieChartDataWithDR: [
                { name: "IC", value: 0 },
                { name: "UC", value: 0 },
                { name: "M", value: 0 },
                { name: "MG", value: 0 },
                { name: "NC", value: 0 },
                { name: "Other", value: 0 },
                { name: "DR", value: 0 },
            ],
            ICCount: 0,
            UCCount: 0,
            MCount: 0,
            MGCount: 0,
            NCCount: 0,
            OthersCount: 0,
            DRCount: 0,
            loading: true,
            excludeDR: false
        }
    }

    componentDidMount() {
        this.updateResultToState(this.props.reportMetadata.uuid)
    }

    componentDidUpdate(prevProps: Readonly<SangerQCReportPieChartProps>, prevState: Readonly<SangerQCReportPieChartState>, snapshot?: any) {
        if (this.props.reportMetadata.uuid !== prevProps.reportMetadata.uuid
            || this.props.refreshToken !== prevProps.refreshToken) {
            this.updateResultToState(this.props.reportMetadata.uuid)
        }
    }

    updateResultToState(reportIndex: string) {
        logger.info("reportIndex = " + reportIndex)
        if (reportIndex === "" || reportIndex === null) {
            return
        }
        this.setState({
            loading: true
        })
        let request = {
            headers: {},
            response: true,
            queryStringParameters: {
                "uuid": reportIndex,
            }
        }
        API.get(sangerQcApiConsts.API_NAME, sangerQcApiConsts.Path.REPORT, request).then(response => {
            let totalClone: number = response.data.totalClone
            this.setState({
                TotalClone: response.data.totalClone,
                pieChartDataWithDR: [
                    { name: "IC", value: this.percent(response.data.icCount, totalClone)},
                    { name: "UC", value: this.percent(response.data.ucCount, totalClone)},
                    { name: "M", value: this.percent(response.data.mCount, totalClone)},
                    { name: "MG", value: this.percent(response.data.mgCount, totalClone)},
                    { name: "NC", value: this.percent(response.data.ncCount, totalClone)},
                    { name: "Other", value: this.percent(response.data.othersCount, totalClone)},
                    { name: "DR", value: this.percent(response.data.drCount, totalClone)},
                ],
                ICCount: response.data.icCount,
                UCCount: response.data.ucCount,
                MCount: response.data.mCount,
                MGCount: response.data.mgCount,
                NCCount: response.data.ncCount,
                OthersCount: response.data.othersCount,
                DRCount: response.data.drCount,
            })
        }).catch(err => {
            // Notify error
            // TODO - ignore and don't show error if report didn't exist in DynamoDB
            logger.error("SangerQCReportPieChart - updateResultToState: Error from API")
            logger.error(err)
            // this.props.showErrorAlert(errorMessage(err) + " Otherwise pie chart will display incorrectly.")
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    percent(value: number, total: number): number {
        let p: number = (value * 100.0) / total;
        return parseFloat(p.toFixed(2))
    }

    excludeDirtyRead(e: boolean): void {
        logger.info("SangerQCReportPieChart - excludeDirtyRead - " + e)
        logger.info(e)
        this.setState({excludeDR: e})
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <Spinner
                        animation="border"
                        size="sm"
                        variant="primary"
                    >
                    </Spinner>
                    <span className="text-primary m-2 fw-bold">Loading pie chart ...</span>
                </>
            )
        }

        if (this.props.reportMetadata.status.toLowerCase() !== SangerQCPipeline.Status.COMPLETED.toLowerCase()
            || this.state === null) {
            return (<></>)
        }

        let piechartData = this.state.pieChartDataWithDR
        if (this.state.excludeDR) {
            let totalCloneWithoutDR = this.state.TotalClone - this.state.DRCount
            piechartData = [
                {name: "IC", value: this.percent(this.state.ICCount, totalCloneWithoutDR)},
                {name: "UC", value: this.percent(this.state.UCCount, totalCloneWithoutDR)},
                {name: "M", value: this.percent(this.state.MCount, totalCloneWithoutDR)},
                {name: "MG", value: this.percent(this.state.MGCount, totalCloneWithoutDR)},
                {name: "NC", value: this.percent(this.state.NCCount, totalCloneWithoutDR)},
                {name: "Other", value: this.percent(this.state.OthersCount, totalCloneWithoutDR)},
            ]
        }

        let totalCloneJSX = (<h4>Total clone = {this.state.TotalClone}</h4>)
        if (this.state.excludeDR) {
            totalCloneJSX = (<h4>Total clone (exclude dirty read) = {this.state.TotalClone - this.state.DRCount}</h4>)
        }

        let dirtyReadTableRowJSX = (<></>)
        if (!this.state.excludeDR) {
            dirtyReadTableRowJSX = (<tr className="table-dark">
                <th>DR</th>
                <th>{piechartData[6].value}%</th>
                <th>{this.state.DRCount}</th>
                <th>Dirty Reads</th>
                <th>100% alignment not observed. Messy reads spanning the entire length of the sequence reads
                </th>
            </tr>)
        }

        return (
            <>
                <Row className="mt-2 mb-3">
                    <Col lg={8}>
                        {totalCloneJSX}
                    </Col>
                    <Col lg={4}>
                        <ButtonGroup className="d-flex justify-content-center">
                            <ToggleButton
                                value={0}
                                variant={this.state.excludeDR ? "outline-primary" : "primary"}
                                checked={!this.state.excludeDR}
                                onClick={(e) => this.excludeDirtyRead(false)}
                            >Include dirty read</ToggleButton>
                            <ToggleButton
                                variant={this.state.excludeDR ? "primary" : "outline-primary"}
                                value={1}
                                checked={this.state.excludeDR}
                                onClick={(e) => this.excludeDirtyRead(true)}
                            >Exclude dirty read</ToggleButton>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8}>
                        <Table bordered style={{ fontSize: "0.65rem" }}>
                            <thead>
                            <tr>
                                <th>Abbreviation</th>
                                <th>Percentage</th>
                                <th>Count</th>
                                <th>Classification</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className="table-success" style={{ fontWeight: "normal" }}>
                                <th>IC</th>
                                <th>{piechartData[0].value}%</th>
                                <th>{this.state.ICCount}</th>
                                <th>Intended combination</th>
                                <th>100% alignment with CRISPR machineries, DPC connectors and sgRNAs.
                                    The retrieved combination is part of the screen library.
                                </th>
                            </tr>
                            <tr className="table-primary">
                                <th>UC</th>
                                <th>{piechartData[1].value}%</th>
                                <th>{this.state.UCCount}</th>
                                <th>Unintended combination</th>
                                <th>100% alignment with CRISPR machineries, DPC connectors and sgRNAs.
                                    The retrieved combination is NOT part of the screen library.
                                </th>
                            </tr>
                            <tr className="table-danger">
                                <th>M</th>
                                <th>{piechartData[2].value}%</th>
                                <th>{this.state.MCount}</th>
                                <th>Mutation</th>
                                <th>
                                    100% alignment not observed. Insertion, deletion or substitution is/are present:
                                    <ul className="mb-0">
                                        <li>M-guide refers to mutation in the sgRNA.</li>
                                        <li>M-backbone refers to mutation in the CRISPR machineries and DPC connectors.</li>
                                    </ul>
                                </th>
                            </tr>
                            <tr className="table-warning">
                                <th>MG</th>
                                <th>{piechartData[3].value}%</th>
                                <th>{this.state.MGCount}</th>
                                <th>Multiple Guides</th>
                                <th>100% alignment of CRISPR and DPC connectors feature libraries only.
                                    Multiple peaks observed in sgRNA region.
                                </th>
                            </tr>
                            <tr className="table-light">
                                <th>NC</th>
                                <th>{piechartData[4].value}%</th>
                                <th>{this.state.NCCount}</th>
                                <th>Negative Clone</th>
                                <th>Empty vector that does not contain insert.</th>
                            </tr>
                            <tr className="table-secondary">
                                <th>Other</th>
                                <th>{piechartData[5].value}%</th>
                                <th>{this.state.OthersCount}</th>
                                <th></th>
                                <th>Unidentified label or mixture of multiple labels with equal importance
                                    such as "M-backbone;MG", "M-guide;MG", "M-backbone;UC", "M-guide;UC".
                                </th>
                            </tr>
                            {dirtyReadTableRowJSX}
                            </tbody>
                        </Table>
                    </Col>
                    <Col lg={4}>
                        <PieChart width={300} height={300} style={{ position: "relative", margin: "0 auto" }}>
                            <Pie
                                data={piechartData}
                                dataKey={"value"}
                                cx="50%"
                                cy="50%"
                                fill="transparent"
                                label={false}
                                innerRadius={90}
                                outerRadius={135}
                                startAngle={450}
                                endAngle={90}
                            >
                                {this.state.pieChartDataWithDR.map( (entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </Col>
                </Row>
            </>
        )
    }
}