import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import {History} from "./History";
import {Import} from "./Import";
import {Search} from "./Search";
import {Update} from "./Update";

interface DatabasesState {
    errorMessage: string
}

interface DatabasesProps {}

export class DataCatalogs extends React.Component<DatabasesProps, DatabasesState> {
    render() {
        return (
            <>
                <h3 className={"mb-3"}>Data Catalog</h3>
                <Tabs defaultActiveKey="search" id="databases-tab" className="mb-3">
                    <Tab eventKey="search" title="Search">
                        <Search />
                    </Tab>
                    <Tab eventKey="import" title="Import">
                        <Import />
                    </Tab>
                    <Tab eventKey="update" title="Update">
                        <Update />
                    </Tab>
                    <Tab eventKey="update_history" title={"History"} >
                        <History />
                    </Tab>
                </Tabs>
            </>
        );
    }
}