import {API, Logger} from "aws-amplify";
import React from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {ColumnDescription} from "react-bootstrap-table-next";
import {USER_EMAIL} from "../../Auth/LoginUser";
import {EventType, FrontendModule} from "../../CommonApi/Enum";
import {UsageTracking} from "../../CommonApi/UsageTracking";
import {SearchBoxComparator} from "../Common/Enum";
import {SearchResultTable} from "../Common/SearchResultTable";
import {gppApiConsts} from "../GuidePlasmidIndel/GppApiConsts";

const logger = new Logger("HGNCSearchResultTable.tsx")

interface HGNCSearchResultTableState {
    data: {
        rows: HGNCGeneEntity[]
    }
    status: "loaded" | "loading" | "error"
    errorMessage: string
}

interface HGNCGeneEntity {
    aliasSymbol: string[],
    ensemblID: string,
    entrezID: string,
    hgncID: string,
    location: string,
    locusGroup: string,
    locusType: string,
    name: string,
    previousSymbol: string[],
    status: string,
    symbol: string,
    uniprodID: string[],
}

interface HGNCSearchResultTableProps {
    refreshToken: string
    keywords: string
    comparator: SearchBoxComparator
}

const HGNC_COLUMN_DEFINITION: Record<string, string> = {
    "symbol": "The official gene symbol that has been approved by the HGNC and is publicly available. Symbols are approved based on specific HGNC nomenclature guidelines.",
    "previousSymbol": "Symbols previously approved by the HGNC for this gene.",
    "aliasSymbol": "Other symbols used to refer to this gene.",
    "name": "The official gene name that has been approved by the HGNC and is publicly available. Names are approved based on specific HGNC nomenclature guidelines.",
    "hgncID": "A unique ID provided by the HGNC for each gene with an approved symbol. IDs are of the format HGNC:n, where n is a unique number",
    "locusType": "Specifies the type of locus described by the given entry. For more detail visit https://www.genenames.org/help/biomart/",
    "status": "Indicates whether the gene is classified as:\n" +
        "\n" +
        "    Approved - these genes have HGNC-approved gene symbols\n" +
        "    Entry withdrawn - these previously approved genes are no longer thought to exist\n" +
        "    Symbol withdrawn - a previously approved record that has since been merged into a another record\n",
}
const HGNC_TABLE_DEF = "HGNC data, update every day."
const HGNC_DATA_ADMIN = "niklas.kasenburg@enginebio.com"

export class HGNCSearchResultTable extends React.Component<HGNCSearchResultTableProps, HGNCSearchResultTableState> {
    constructor(props: HGNCSearchResultTableProps) {
        super(props);
        this.state = {
            data: {
                rows: [],
            },
            status: "loaded",
            errorMessage: "",
        }
    }

    componentDidUpdate(prevProps: Readonly<HGNCSearchResultTableProps>, prevState: Readonly<HGNCSearchResultTableState>, snapshot?: any) {
        if (prevProps.refreshToken !== this.props.refreshToken) {
            this.filterByColumns(this.props.keywords, this.props.comparator)
        }
    }

    filterByColumns(keywords: string, comparator: string) {
        logger.debug("Start HGNC API call")
        this.setState({status: "loading", errorMessage: ""})
        UsageTracking.recordEvent({
            module: FrontendModule.DataCatalog,
            event: EventType.SearchInternalApi,
            table: "hgnc_approved_symbol",
            database: "hgnc_internal_api",
            keywords: keywords, // Comma seperated keywords is splited to seperate Cloudwatch log at backend.
        })
        API.get(
            // TODO in future - move HGC api from GPP to common-api, or make both private and public endpoint for it.
            gppApiConsts.API_NAME,
            gppApiConsts.hgnc.path.GET_GENE_SYMBOL,
            {
                queryStringParameters: {
                    keywords: keywords,
                    onlyApprovedSymbols: "false",
                    returnBestMatchOnly: "false",
                    userTracking: USER_EMAIL
                },
            }).then(response => {
                logger.debug("Response from API")
                logger.debug(response)
                let rows: HGNCGeneEntity[] = []
                for (var row of response["data"]) {
                    rows.push(...row["approvedSymbols"])
                }
                logger.debug(rows)
                this.setState({
                    data: {
                        rows: rows
                    },
                    status: "loaded",
                    errorMessage: "",
                })
            }).catch(err => {
                logger.error(err)
                this.setState({
                    status: "error",
                    errorMessage: err+""
                })
            }).finally(() => {

            })
    }

    render() {
        const columnHeaderFormatter = (column: ColumnDescription<any>, colIndex: number) =>
            <OverlayTrigger
                delay={{show: 250, hide: 400}}
                placement={"top"}
                overlay={
                    <Popover className={"ms-2"}
                             id={`hgnc-${column.dataField}-definition`}>
                        <Popover.Header as={"h3"}>Definition</Popover.Header>
                        <Popover.Body>{HGNC_COLUMN_DEFINITION[column.dataField] ? HGNC_COLUMN_DEFINITION[column.dataField] : column.text}</Popover.Body>
                    </Popover>
                }>
                <label htmlFor={`hgnc-${column.dataField}-definition`}>{column.text}</label>
            </OverlayTrigger>

        return <SearchResultTable
            refreshToken={this.props.refreshToken}
            status={this.state.status}
            errorMessage={this.state.errorMessage}
            title={"HGNC - HUGO Gene Nomenclature Committee symbols"}
            legends={<></>}
            rowClasses={() => ""}
            dataAdmin={HGNC_DATA_ADMIN}
            tableDefinitionStatus={"loaded"}
            tableDefinitionErrorMessage={""}
            tableDescription={HGNC_TABLE_DEF}

            keyField={"hgncID"}
            columnsDescriptions={[
                {dataField: "name", text: "Name", headerFormatter: columnHeaderFormatter},
                {dataField: "hgncID", text: "HGNC ID", headerFormatter: columnHeaderFormatter},
                {
                    dataField: "symbol",
                    text: "Official Symbol",
                    headerFormatter: columnHeaderFormatter,
                    formatter: (cell: string) => {
                        return <b className={"text-success"}>{cell}</b>
                    }
                },
                {
                    dataField: "aliasSymbol",
                    text: "Alias Symbol",
                    headerFormatter: columnHeaderFormatter,
                    formatter: (cell: string[]) => {
                        return <>{cell.join(", ")}</>
                    }
                },
                {
                    dataField: "previousSymbol",
                    text: "Previous Symbol",
                    headerFormatter: columnHeaderFormatter,
                    formatter: (cell: string[]) => {
                        return <>{cell.join(", ")}</>
                    }
                },
                {dataField: "locusType", text: "Locus Type", headerFormatter: columnHeaderFormatter},
                {
                    dataField: "status",
                    text: "Status",
                    headerFormatter: columnHeaderFormatter,
                    formatter: (cell: string) => {
                        if (cell.toLowerCase() === "entry withdrawn" || cell.toLowerCase() === "symbol withdrawn") {
                            return <b className={"text-white bg-danger"}>{cell}</b>
                        }
                        return cell
                    }
                },
            ]}
            columnsDefinitions={HGNC_COLUMN_DEFINITION}
            columnsMetadataStatus={"loaded"}
            columnsMetadataErrorMessage={""}
            columnsFormats={{
                "previousSymbol": (cell: string[]) => {
                    return <>{cell.map((symbol: string) => <>{symbol + ", "}</>)}</>
                },
                "aliasSymbol": (cell: string[]) => {
                    return <>{cell.map((alias: string) => <>{alias + ", "}</>)}</>
                }
            }}

            tableData={this.state.data.rows}
            tableDataStatus={this.state.status}
            tableDataErrorMessage={this.state.errorMessage}
        />;
    }
}