import {shortenEnv} from "../../Const";

export const synergyFinderConsts = {
    API_NAME: shortenEnv + "-synergy-finder",
    API_ENDPOINT: "https://" + shortenEnv + "-synergy-finder.enginebio.io"
}

export enum SynergyFinderPipelineStatus {
    IN_PROGRESS = "In progress", // TODO - put a time range here to let user know how long it will take
    FAILED = "Failed",
    COMPLETED = "Completed",
}

export const BUCKET_NAME = shortenEnv + "-synergy-finder-excel-upload"

export enum ApiCallStatus {
    Initial = "initial",
    Loading = "loading",
    Completed = "completed",
    Error = "error"
}

export enum SynergyFinderCalculationMethod {
    ZIP = "ZIP",
    HSA = "HSA",
    Bliss = "Bliss",
    Loewe = "Loewe"
}