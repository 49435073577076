import React from "react";
import {SearchBoxComparator} from "../../Common/Enum";
import {SearchResultTableWithUpdateUI} from "../../Common/SearchResultTableWithUpdateUI";
import {View} from "../Enum";
import {genGenPlateApiConst} from "../GenGenPlateApiConst";
import {GenGenPlateTableDataApi} from "../GenGenPlateTableDataApi";
import {GenGenPlateRawDataForm} from "./GenGenPlateRawDataForm";

interface GenGenPlateRawDataSearchResultTableProps {
    keywords: string
    comparator: SearchBoxComparator
    refreshToken: string
    enableFilter: boolean
    view: View
}

interface GenGenPlateRawDataSearchResultTableState {}

export class GenGenPlateRawDataSearchResultTable extends React.Component<GenGenPlateRawDataSearchResultTableProps, GenGenPlateRawDataSearchResultTableState> {
    static defaultProps = {
        refreshToken: "",
        keywords: "",
        enableFilter: true,
    }

    render() {
        return <SearchResultTableWithUpdateUI
            refreshToken={this.props.refreshToken}
            databaseName={genGenPlateApiConst.DATABASE_NAME}
            tableName={genGenPlateApiConst.tableName.RAW_DATA}
            keywords={this.props.keywords}
            columns={""}
            comparator={this.props.comparator}
            keyField={"id"}
            title={this.props.view === View.Update ? "" : "Gen Gen Raw Data"}
            legends={<></>}
            rowClasses={row => "table-light"}
            columnsHidden={["collectionRawData", "lastModified"]}
            columnsDisplayInOrder={["plateId", "owner"]}
            enableFilter={this.props.enableFilter}
            view={this.props.view}
            modalClassName={"modal-larger-width"}
            modalForm={GenGenPlateRawDataForm}
            searchTable={GenGenPlateTableDataApi.searchTable}
        />
    }
}