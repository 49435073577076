export function wellClassFormatter(cell: string): any {
    if (cell === "empty") {
        return "bg-secondary text-white"
    }
    if (cell === "nv" || cell === "nv_puro") {
        return "bg-primary text-white"
    }
    if (cell === "media_only") {
        return "bg-warning text-dark"
    }

    if (cell === "need_update") {
        return "bg-danger text-white"
    }
    return "bg-transparent text-black"
}