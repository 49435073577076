export enum View {
    Read = "read",
    Update = "update",
    Import = "import"
}

export enum DeliveryMethod {
    Lentivirus = "Lentivirus",
    None = ""
}

export enum CollectionRound {
    None = "",
    First = "first",
    Second = "second",
    Final = "final"
}