import React from "react";
import {Alert} from "react-bootstrap";
import * as yup from "yup";
import {SearchBoxComparator} from "./Common/Enum";
import {HistorySearchResultTable} from "./Common/HistorySearchResultTable";
import {View} from "./GenGenPlate/Enum";
import {GenGenPlateMetadataSearchResultTable} from "./GenGenPlate/Metadata/GenGenPlateMetadataSearchResultTable";
import {GenGenPlateMapSearchResultTable} from "./GenGenPlate/PlateMap/GenGenPlateMapSearchResultTable";
import {GenGenPlateRawDataSearchResultTable} from "./GenGenPlate/RawData/GenGenPlateRawDataSearchResultTable";
import {IndelSearchResultTable} from "./GuidePlasmidIndel/IndelSearchResultTable";
import {PlasmidSearchResultTable} from "./GuidePlasmidIndel/PlasmidSearchResultTable";
import {WesternBlotSearchResultTable} from "./GuidePlasmidIndel/WesternBlotSearchResultTable";

interface SearchUIForUpdateState {
    initialValues: {
        id: string
    },
    schema: any,
}

interface SearchUIForUpdateProps {
    table: string
    searchTitle: string
    temporaryDisable: boolean
    disableReason: string
}

export class UpdateSearchUI extends React.Component<SearchUIForUpdateProps, SearchUIForUpdateState> {
    static defaultProps = {
        temporaryDisable: false,
        disableReason: "",
    }

    constructor(props: SearchUIForUpdateProps) {
        super(props);
        this.state = {
            initialValues: {
                id: ""
            },
            schema: yup.object({id: yup.string().required()}),
        }
    }

    searchResult(table: string) {
        switch (table) {
            case "indel_performance":
                return <IndelSearchResultTable
                    comparator={SearchBoxComparator.Like}
                    view={View.Update}
                    legends={false}
                    enableFilter={true}
                />
            case "plasmid":
                return <PlasmidSearchResultTable
                    comparator={SearchBoxComparator.Like}
                    view={View.Update}
                    enableFilter={true}
                />
            case "western_blot":
                return <WesternBlotSearchResultTable
                    comparator={SearchBoxComparator.Like}
                    view={View.Update}
                    enableFilter={true}
                />
            case "update_history":
                return <HistorySearchResultTable
                    comparator={SearchBoxComparator.Like}
                />
            case "gen_gen_plate_metadata":
                return <GenGenPlateMetadataSearchResultTable
                    comparator={SearchBoxComparator.Like}
                    view={View.Update}
                    enableFilter={true}
                />
            case "gen_gen_plate_map":
                return <GenGenPlateMapSearchResultTable
                    comparator={SearchBoxComparator.Like}
                    view={View.Update}
                    enableFilter={true}
                />
            case "gen_gen_plate_raw_data":
                return <GenGenPlateRawDataSearchResultTable
                    comparator={SearchBoxComparator.Like}
                    view={View.Update}
                    enableFilter={true}
                />
            default:
                return <></>
        }
    }

    render() {
        return <>
            {this.props.temporaryDisable && <Alert variant={"warning"} className={"mb-3"}>
                <Alert.Heading>Warning!</Alert.Heading>
                {this.props.disableReason}
            </Alert>}
            {this.searchResult(this.props.table)}
        </>;
    }
}