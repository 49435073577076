export enum SearchBoxComparator {
    Like = "like",
    Equal = "equal"
}

export enum ApiCallStatus {
    NoData = "no-data",
    Loading = "loading",
    Error = "error",
    Completed = "completed",
    NotSupported = "not-supported"
}