import {Logger} from "aws-amplify";
import React from "react";
import {Card} from "react-bootstrap";
import BootstrapTable, {ColumnDescription, ColumnFormatter} from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const logger = new Logger("SearchResultTable.tsx")

interface SearchResultTableProps {
    title: string
    smallTitle: string
    keyField: string
    legends: any
    rowClasses: ((row: any, rowIndex: number) => string)
    columnsFormats: Record<string, ColumnFormatter<any, any, any> | undefined>

    status: "loading" | "error" | "loaded"
    errorMessage: string
    refreshToken: string

    dataAdmin: string
    tableDefinitionStatus: "loaded" | "loading" | "error"
    tableDefinitionErrorMessage: string
    tableDescription: string

    columnsDescriptions: ColumnDescription[]
    columnsDefinitions: Record<string, string>
    columnsMetadataStatus: "loaded" | "loading" | "error"
    columnsMetadataErrorMessage: string

    tableData: any[]
    tableDataStatus: "loading" | "error" | "loaded"
    tableDataErrorMessage: string
    displayPagination: boolean
}

interface SearchResultTableState {}

export class SearchResultTable extends React.Component<SearchResultTableProps, SearchResultTableState> {
    static defaultProps = {
        dataAdmin: "",
        displayPagination: true,
        smallTitle: "",
    }

    render() {
        logger.debug("render - tableData = ", this.props.tableData)

        return <>
            {this.props.title !== "" && <h3 className={"text-primary mt-3"}>{this.props.title}</h3>}
            {this.props.smallTitle !== "" && <p className={"mt-3"}>{this.props.smallTitle}</p>}
            <Card className="bg-light mb-3">
                <Card.Body>
                    {(
                        this.props.status === "loading" ||
                        this.props.columnsMetadataStatus === "loading" ||
                        this.props.tableDefinitionStatus === "loading" ||
                        this.props.tableDataStatus === "loading"
                    ) &&
                    <div className={"text-primary"}>Searching ...</div>}
                    {(
                        this.props.status === "error" ||
                        this.props.columnsMetadataStatus === "error" ||
                        this.props.tableDefinitionStatus === "error" ||
                        this.props.tableDataStatus === "error") && <>
                        <div className={"text-danger"}>Something wrong, please refresh</div>
                    </>}
                    {(
                        this.props.status === "loaded"  &&
                        this.props.columnsMetadataStatus === "loaded" &&
                        this.props.tableDefinitionStatus === "loaded" &&
                        this.props.tableDataStatus === "loaded"
                    ) && <div className="table-responsive">
                        {this.props.legends}
                        <BootstrapTable
                            hover
                            caption={this.props.dataAdmin === "" ? "" : `Admin: ${this.props.dataAdmin}`}
                            bordered={true}
                            keyField={this.props.keyField}
                            columns={this.props.columnsDescriptions}
                            data={this.props.tableData}
                            rowClasses = {this.props.rowClasses}
                            noDataIndication={<h6 className={"text-primary"}>No data found!</h6>}
                            pagination={this.props.displayPagination ? paginationFactory({
                                sizePerPageList: [15],
                                hideSizePerPage: true,
                                showTotal: true
                            }) : undefined}
                        />
                    </div>}
                </Card.Body>
            </Card>
        </>;
    }
}