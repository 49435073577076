import React from "react";
import {Alert, Button, Container, Image, Nav, Navbar} from "react-bootstrap";
import {currentEnv} from "../Const";
import enginebio_icon from "../enginebio_icon.png";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import {SangerQC} from "./SangerQC/SangerQC";
import {getUsername} from "./Auth/LoginUser";
import {ExperimentalFeatures} from "./ExperimentalFeatures/ExperimentalFeatures";
import {SynergyFinder} from "./SynergyFinder/SynergyFinder";
import {DataCatalogs} from "./DataCatalog/DataCatalogs";

interface BioToolsNavBarState {
    currentView: string
}

interface BioToolsNavBarProps {
    signOut: () => any
    user: string
}

export class BioToolsUi extends React.Component<BioToolsNavBarProps, BioToolsNavBarState> {
    constructor(props: any) {
        console.log("BioToolsNavBar - constructor")
        super(props);
        this.state = {
            currentView: "data-catalogs"
        }
    }

    componentDidMount() {
        getUsername()
    }

    setView(currentView: string) {
        console.log("User set view = " + currentView)
        this.setState({
            currentView: currentView
        })
    }

    render() {
        return (
            <>
                <Navbar bg={"dark"} expand="lg" className="p-2" variant={"dark"} fixed="top">
                    <Navbar.Brand href="#">
                        <Image src={enginebio_icon} style={{ width: "2.5rem", height: "2.5rem"}}></Image>
                    </Navbar.Brand>
                    <NavbarCollapse className="collapse" id="navbarNav">
                        <Nav>
                            <Nav.Item>
                                <Nav.Link onClick={() => this.setView("data-catalogs")}><h5>Data Catalog</h5></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => this.setView("data-visualization")}><h5>Data Visualization</h5></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => this.setView("sanger-qc")}><h5>Sanger QC</h5></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => this.setView("synergy-finder")}><h5>Synergy Finder</h5></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </NavbarCollapse>
                    <Button variant={"secondary"} onClick={this.props.signOut}>Sign out</Button>
                </Navbar>
                <Container className="p-4" style={{ marginTop: 60, maxWidth: "1600px" }} >
                    {currentEnv !== "production" && <Alert variant={"warning"}>You are using testing environment, please go to <a href={"https://enginebio.io"}>official environment</a> for day to day works.</Alert>}
                    {
                        {
                            "data-catalogs": <DataCatalogs />,
                            "data-visualization": <ExperimentalFeatures />,
                            "sanger-qc": <SangerQC />,
                            "synergy-finder": <SynergyFinder/>
                        }[this.state.currentView]
                    }
                </Container>
            </>
        );
    }
}