import React from "react";
import {Card, Col, Nav, Row, Tab} from "react-bootstrap";
import {UpdateSearchUI} from "./UpdateSearchUI";

export class Update extends React.Component<any, any> {
    render() {
        return <Tab.Container id="left-tabs-example" defaultActiveKey="plasmid">
            <Row>
                <Col lg={2}>
                    <Card bg={"light"}>
                        <Card.Body>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item role={"button"}>
                                    <Nav.Link eventKey="plasmid">Plasmid</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={"text-secondary"}>Gen-gen experiment</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"} className={"ms-2"}>
                                    <Nav.Link eventKey="gen_gen_plate_metadata">Metadata</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"} className={"ms-2"}>
                                    <Nav.Link eventKey="gen_gen_plate_map">Plate Map</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"} className={"ms-2"}>
                                    <Nav.Link eventKey="gen_gen_plate_raw_data">Raw Data</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"} className={"ms-2"}>
                                    <Nav.Link eventKey="indel_performance">Indel</Nav.Link>
                                </Nav.Item>
                                <Nav.Item role={"button"} className={"ms-2"}>
                                    <Nav.Link eventKey="western_blot">Western Blot</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={10}>
                    <Tab.Content>
                        <Tab.Pane eventKey="plasmid">
                            <Row className={"g-0 mb-3"}>
                                <UpdateSearchUI
                                    searchTitle={"Search by guide sequence, plasmid name and plasmid ID"}
                                    table={"plasmid"}
                                />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="indel_performance">
                            <Row className={"g-0"}>
                                <UpdateSearchUI
                                    searchTitle={"Search by gene name, plate ID, plasmid ID, indel ID, cell line and guide sequence"}
                                    table={"indel_performance"}
                                />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="western_blot">
                            <Row className={"g-0"}>
                                <UpdateSearchUI
                                    searchTitle={"Search by gene name, plate ID, plasmid ID, Western Blot ID, cell line and guide sequence"}
                                    table={"western_blot"}
                                />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="gen_gen_plate_metadata">
                            <Row className={"g-0"}>
                                <UpdateSearchUI
                                    table={"gen_gen_plate_metadata"}
                                    searchTitle={"Search by plate ID, gene A, gene B, owner and project"}
                                />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="gen_gen_plate_map">
                            <Row className={"g-0"}>
                                <UpdateSearchUI
                                    table={"gen_gen_plate_map"}
                                    searchTitle={"Search by plate ID, Dotmatic ID, gene A, gene B, owner and project"}
                                />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="gen_gen_plate_raw_data">
                            <Row className={"g-0"}>
                                <UpdateSearchUI
                                    table={"gen_gen_plate_raw_data"}
                                    searchTitle={"Search by plate ID, gene A, gene B, owner and project"}
                                />
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="gen_gen_plate_calculated_result">
                            <Row className={"g-0"}>
                                <UpdateSearchUI
                                    table={"gen_gen_plate_calculated_result"}
                                    searchTitle={"Search by plate ID, gene A, gene B, owner and project"}
                                />
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>;
    }
}