import React from "react";
import {Col, Row, Spinner} from "react-bootstrap";
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import '../CommonCss/TableSmall.css'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import {SangerQCHistoryTableRow} from "./SangerQCHistoryTable";
import {API, Logger} from "aws-amplify";
import {sangerQcApiConsts} from "./Const";

interface SangerQCReportCSVTableState {
    columns: ColumnDescription[]
    rows: SangerQCReportCSVTableRow[]
    loading: boolean
}

const { ExportCSVButton } = CSVExport;

interface SangerQCReportCSVTableRow {
    cloneId: string,
    label: string,
    sgA: string,
    sgB: string,
    cloneConstruct: string,
    sgAId: string,
    sgBId: string,
    sgALength: number,
    sgBLength: number,
    clone: string,
    hU6: string,
    scf1: string,
    mU6: string,
    scf4: string,
}

interface SangerQCReportTableProps {
    reportMetadata: SangerQCHistoryTableRow
    showErrorAlert(message: string): void
    refreshToken: string
}

const logger = new Logger("SangerQCReportTable")

export class SangerQCReportTable extends React.Component<SangerQCReportTableProps, SangerQCReportCSVTableState> {
    constructor(props: any) {
        super(props);
        this.state = {
            columns: [
                {
                    dataField: "clone_id",
                    text: "Clone ID",
                    filter: textFilter({
                        placeholder: "Search clone ID",
                        className: "table-small-text-filter",
                    })
                },
                {
                    dataField: "finalLabel",
                    text: "Final label",
                    filter: textFilter({
                        placeholder: "Search label",
                        className: "table-small-text-filter",
                    })
                },
                {dataField: "sgA", text: "sgA"},
                {dataField: "sgB", text: "sgB"},
                {dataField: "clone_construct", text: "Clone construct"},
                {dataField: "sgA_id", text: "sgA ID"},
                {dataField: "sgB_id", text: "sgB ID"},
                {dataField: "sgA_length", text: "sgA length"},
                {dataField: "sgB_length", text: "sgB length"},
                {dataField: "label", text: "Sub-label", hidden: true},
                {dataField: "clone", text: "clone", hidden: true},
                {dataField: "hU6", text: "hU6", hidden: true},
                {dataField: "scf1", text: "scf1", hidden: true},
                {dataField: "mU6", text: "mU6", hidden: true},
                {dataField: "scf4", text: "scf4", hidden: true},
                {dataField: "spacer", text: "spacer", hidden: true},
            ],
            rows: [],
            loading: true
        }
    }

    componentDidMount() {
        this.getReportResult(this.props.reportMetadata.uuid)
    }

    getReportResult(reportIndex: string) {
        this.setState({
            loading: true
        })
        let request = {
            headers: {},
            response: true,
            queryStringParameters: {
                "uuid": this.props.reportMetadata.uuid,
            }
        }
        API.get(sangerQcApiConsts.API_NAME, sangerQcApiConsts.Path.REPORT, request).then(response => {
            logger.debug("Response from API - ", response)
            this.setState({
                rows: response.data.data
            })
        }).catch(err => {
            // TODO - ignore and don't show error if report didn't exist in DynamoDB
            logger.error("SangerQCReportTable - getReportResult: Error from API", err)
            // this.props.showErrorAlert(errorMessage(err))
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    componentDidUpdate(prevProps: Readonly<SangerQCReportTableProps>, prevState: Readonly<SangerQCReportCSVTableState>, snapshot?: any) {
        logger.debug("componentDidUpdate")
        if (this.props.reportMetadata.uuid !== prevProps.reportMetadata.uuid
            || this.props.refreshToken !== prevProps.refreshToken) {
            this.getReportResult(this.props.reportMetadata.uuid)
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <Spinner
                        animation="border"
                        size="sm"
                        variant="primary"
                    >
                    </Spinner>
                    <span className="text-primary m-2 fw-bold">Loading CSV table ...</span>
                </>
            )
        }

        if (this.props.reportMetadata.status.toLowerCase() !== "completed") {
            return (<></>)
        }

        return (
            <Row>
                <Col lg={12}>
                    <ToolkitProvider
                        keyField="clone_id"
                        data={this.state.rows}
                        columns={this.state.columns}
                        exportCSV={true}
                    >
                        {
                            props => (
                                <div>
                                    <ExportCSVButton className="btn btn-success btn-sm float-end" {... props.csvProps}>Export to CSV</ExportCSVButton>
                                    <BootstrapTable
                                        striped
                                        classes="table-small-text"
                                        filter={filterFactory()}
                                        {... props.baseProps}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </Col>
            </Row>
        );
    }
}