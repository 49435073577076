import {API, Logger} from "aws-amplify";
import {commonApiConsts} from "./Const";

const logger = new Logger("TableMetadata.ts")

export interface TableDefinition {
    dataAdmin: string
    description: string
}

export class TableMetadata {
    static getTableDefinition(table: string, database: string, callback: (tableDef: TableDefinition) => void, errCallback: (err: any) => void): void {
        /*
            * common-api - Using some AWS services specialize in keeping metadata?:
            * - Pros:
            *   - No duplicated efforts, especially some APIs use Python.
            *   - Systematic approach to tracking schema changes
            * - Cons:
            *   - Change in table schemas are now less responsive, up to an hour before Glue crawler triggered.
            *   - No enums possible, at least right now.
            *   - Needs to perform data migration for update_history table.
            * hardcode in the UI:
            * - Pros:
            *   - Same as common api but much easier to set-up.
            * - Cons:
            *   - We need to remember to update manually. Keeping DB schema and UI hardcode values sync is quite a task.
            *   - But to be frank front-end keep their schema too, regarding enums API
            *       why not convert these fields to Enum and dropdown list get values from Reach Enum object directly?
            *   - Somehow the idea sounds stupid.
            * Individually at each service:
            * - Cons:
            *   - Complicated, need custom code - Python & Go code for each new APIs, Javascript/Go code for Athena table, etc.
            * - Pros:
            *   - No migration required
            *   - Little code change in the front-end.
            * */
        // Not user-initiated, no need for recordEvent()
        // FIXME - for gen_gen_plate_aggregated --> it a view, no actual table exists in DB and thus in datahub --> how can we handle this?
        API.get(
            commonApiConsts.API_NAME,
            "/table-metadata/table-definition",
            {
                queryStringParameters: {
                    table: table,
                    database: database
                },
            }).then(response => {
            logger.info("Response from table-definition API", response)
            callback(response["data"])
        }).catch(err => {
            logger.error(err)
            errCallback(err)
        }).finally(() => {

        })
        return
    }

    static getColumnsDefinitions(table: string, database: string, callback: (columnsDef: Record<string, string>) => void, errorCallback: (err: any) => void): void {
        // Not user-initiated, no need for recordEvent()
        API.get(
            commonApiConsts.API_NAME,
            // FIXME - for gen_gen_plate_aggregated --> it a view, no actual table exists in DB and thus in datahub --> how can we handle this?
            "/table-metadata/columns-definitions",
            {
                queryStringParameters: {
                    table: table,
                    database: database
                },
            }).then(response => {
            logger.debug("Response from API", response)
            callback(response["data"])
        }).catch(err => {
            logger.error(err)
            errorCallback(err)
        })
        return
    }
}