import {API, Logger} from "aws-amplify";
import {FormikHelpers, FormikValues} from "formik";
import React from "react";
import {Alert, Card, Col, Form, Row} from "react-bootstrap";
import BootstrapTable, {ColumnDescription} from "react-bootstrap-table-next";
import {v4 as uuidv4} from "uuid";
import * as yup from "yup";
import {DataAudit} from "../../../CommonApi/DataAudit";
import {EventType, FrontendModule} from "../../../CommonApi/Enum";
import {UsageTracking} from "../../../CommonApi/UsageTracking";
import {ApiCallStatus} from "../../Common/Enum";
import {DataImportFormSubmissionStatus, HtmlForm} from "../../Common/HtmlForm";
import {CollectionRound, View} from "../Enum";
import {genGenPlateApiConst} from "../GenGenPlateApiConst";
import {PlateRow} from "../PlateRow";
import {GenGenPlateCalculatedResult} from "./GenGenPlateCalculatedResult";

const logger = new Logger("GenGenPlateRawDataForm")

export interface GenGenPlateRawData {
    plateId: string
    collectionRound: CollectionRound
    collectionDay: number
    collectionRawData: string
    owner?: string
    lastModified?: Date
}

interface GenGenPlateRawDataFormState {
    initialValues: GenGenPlateRawData
    submitStatus: DataImportFormSubmissionStatus
    getStatus: ApiCallStatus
    getMessage: string
    calculationResultRefreshToken: string
}

interface GenGenPlateRawDataFormProps {
    view: View
    plateId: string
    displayHeader: boolean
    initialValues: GenGenPlateRawData
}

const RAW_DATA_COLUMN_DEF_VIEW_ONLY: ColumnDescription[] = [
    {dataField: "0", text: ""},
    {dataField: "A", text: "1"},
    {dataField: "B", text: "2"},
    {dataField: "C", text: "3"},
    {dataField: "D", text: "4"},
    {dataField: "E", text: "5"},
    {dataField: "F", text: "6"},
    {dataField: "G", text: "7"},
    {dataField: "H", text: "8"},
    {dataField: "I", text: "9"},
    {dataField: "J", text: "10"},
    {dataField: "K", text: "11"},
    {dataField: "L", text: "12"},
]

export class GenGenPlateRawDataForm extends React.Component<GenGenPlateRawDataFormProps, GenGenPlateRawDataFormState> {
    static defaultProps = {
        plateId: "",
        displayHeader: true,
        initialValues: null,
    }

    constructor(props: GenGenPlateRawDataFormProps) {
        super(props);
        this.state = {
            initialValues: props.initialValues == null ? {
                plateId: "",
                collectionRound: CollectionRound.None,
                collectionDay: 0,
                collectionRawData: "",
            } : this.props.initialValues,
            submitStatus: {
                status: ApiCallStatus.NoData,
                message: ""
            },
            getStatus: ApiCallStatus.NoData,
            getMessage: "",
            calculationResultRefreshToken: uuidv4(),
        }
    }

    onSubmit(values: any, formikHelpers: FormikHelpers<FormikValues>): void | Promise<any> {
        formikHelpers.setSubmitting(true)
        this.setState({
            submitStatus: {
                status: ApiCallStatus.Loading,
                message: "",
            },
        }, () => {
            if (this.props.view === View.Import) {
                this.apiPut(values, formikHelpers)
            } else if (this.props.view === View.Update) {
                this.apiPatch(values, formikHelpers)
            }
        })
    }

    apiPut(values: GenGenPlateRawData, formikHelpers: FormikHelpers<FormikValues>) {
        // TODO in future - Raw data form is a special case, where it doesn't have owner, instead links to metadata table with owner column,
        // and it's important that people don't upload raw data of plate belongs to different owner
        // So we need to send current user name to back-end, to perform join with metadata table to check if owner === user

        console.log("GenGenPlateRawDataForm - apiPut - values = ", values)
        UsageTracking.recordEvent({
            uuid: uuidv4(),
            module: FrontendModule.DataCatalog,
            event: EventType.Import,
            table: genGenPlateApiConst.tableName.RAW_DATA,
            database: genGenPlateApiConst.DATABASE_NAME,
            keywords: "",
            timestamp: new Date()
        })
        // TODO - Test with actualy reading copy/paste from machine --> check if it can auto-detect fringe columns A, B, C and first row 1, 2, 3 and filter it out
        this.setState({
            submitStatus: {
                status: ApiCallStatus.Loading,
                message: ""
            }
        }, () => {
            API.put(
                genGenPlateApiConst.API_NAME,
                genGenPlateApiConst.path.RAW_DATA,
                {
                    body: values
                }
            ).then(response => {
                DataAudit.recordEvent({
                    database: genGenPlateApiConst.DATABASE_NAME,
                    table: genGenPlateApiConst.tableName.RAW_DATA,
                    rowId: values.plateId,
                    updateNote: "",
                    dataObject: values
                })
                logger.info("Response from API ", response)
                let successMsg: string = "Plate " + values.plateId + ", " +
                    values.collectionRound.toLowerCase() + " collection round is uploaded"
                if (values["collectionRound"] === CollectionRound.Final) {
                    this.setState({
                        initialValues: values,
                        submitStatus: {
                            status: ApiCallStatus.Completed,
                            message: successMsg
                        },
                    })
                } else {
                    this.setState({
                        initialValues: values,
                        submitStatus: {
                            status: ApiCallStatus.Completed,
                            message: successMsg
                        },
                    })
                    formikHelpers.resetForm()
                }
            }).catch(err => {
                logger.error(err)
                if (err.response && err.response.data) {
                    this.setState({
                        submitStatus: {
                            status: ApiCallStatus.Error,
                            message: err.response.data.message
                        }
                    })
                } else {
                    this.setState({
                        submitStatus: {
                            status: ApiCallStatus.Error,
                            message: err.toString(),
                        }
                    })
                }
            }).finally(() => {
                formikHelpers.setSubmitting(false) // Don't reset form, ppl may want to compare raw data with the graph
            })
        })
    }

    apiPatch(values: any, formikHelpers: FormikHelpers<FormikValues>) {
        console.log("GenGenPlateRawDataForm - apiPut - values = ", values)
        UsageTracking.recordEvent({
            uuid: uuidv4(),
            module: FrontendModule.DataCatalog,
            event: EventType.Update,
            table: genGenPlateApiConst.tableName.RAW_DATA,
            database: genGenPlateApiConst.DATABASE_NAME,
            keywords: "",
            timestamp: new Date()
        })
        this.setState({
            submitStatus: {
                status: ApiCallStatus.Loading,
                message: ""
            }
        }, () => {
            API.patch(
                genGenPlateApiConst.API_NAME,
                genGenPlateApiConst.path.RAW_DATA,
                {
                    body: values
                }
            ).then(response => {
                DataAudit.recordEvent({
                    database: genGenPlateApiConst.DATABASE_NAME,
                    table: genGenPlateApiConst.tableName.RAW_DATA,
                    rowId: values.plateId,
                    updateNote: values.updateNote,
                    dataObject: values
                })

                logger.info("Response from API ", response)
                let successMsg: string = `Plate ${values.plateId} updated`
                if (values["collectionRound"].toLowerCase() === CollectionRound.Final) {
                    this.setState({
                        initialValues: values,
                        submitStatus: {
                            status: ApiCallStatus.Completed,
                            message: successMsg
                        },
                        calculationResultRefreshToken: uuidv4(),
                    })
                } else {
                    this.setState({
                        initialValues: values,
                        submitStatus: {
                            status: ApiCallStatus.Completed,
                            message: successMsg
                        },
                    })
                }
            }).catch(err => {
                logger.error(err)
                if (err.response && err.response.data) {
                    this.setState({
                        submitStatus: {
                            status: ApiCallStatus.Error,
                            message: err.response.data.message
                        }
                    })
                } else {
                    this.setState({
                        submitStatus: {
                            status: ApiCallStatus.Error,
                            message: err.toString(),
                        }
                    })
                }
            }).finally(() => {
                formikHelpers.setSubmitting(false) // Don't reset form for update
            })
        })
    }

    render() {
        if (this.props.view === View.Read) {
            if (!this.state.initialValues || !this.state.initialValues.collectionRawData || this.state.initialValues.collectionRawData === "") {
                return <Alert variant={"info"}>No raw data uploaded</Alert>
            }

            return <Row>
                <Col md={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            {this.state.initialValues.collectionRound.charAt(0).toUpperCase() + this.state.initialValues.collectionRound.slice(1)} collection round - day {this.state.initialValues.collectionDay}
                        </Form.Label>
                        <BootstrapTable
                            hover
                            bordered={true}
                            wrapperClasses={"table-responsive"}
                            keyField={"0"}
                            columns={RAW_DATA_COLUMN_DEF_VIEW_ONLY}
                            data={PlateRow.convertStrToPlateMapRowList(this.state.initialValues.collectionRawData)}
                            rowClasses={() => ""}
                            noDataIndication={<h6 className={"text-primary"}>No data found!</h6>}/>
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <GenGenPlateCalculatedResult
                        collectionRound={this.state.initialValues.collectionRound}
                        refreshToken={this.state.calculationResultRefreshToken}
                        view={this.props.view}
                        plateId={this.state.initialValues.plateId}/>
                </Col>
            </Row>
        }

        return <Card bg={"light"}>
            {this.props.displayHeader && <Card.Header><b>Add new gen-gen plate raw data</b></Card.Header>}
            <Card.Body>
                <HtmlForm
                    databaseName={genGenPlateApiConst.DATABASE_NAME}
                    tableName={genGenPlateApiConst.tableName.RAW_DATA}
                    initialValues={this.state.initialValues}
                    rowsOfFormFields={[
                        {
                            colSize: 4,
                            fields: [
                                {name: "plateId", dataType: "text", disable: this.props.view === View.Update},
                                {name: "collectionRound", dataType: "select", disable: false},
                                {name: "collectionDay", dataType: "number", disable: false},
                            ]
                        },
                        {
                            colSize: 12,
                            fields: [
                                {name: "collectionRawData", dataType: "textarea", disable: false}
                            ]
                        },
                    ]}
                    schema={yup.object({
                        plateId: yup.string().required(),
                        collectionRound: yup.string().required(),
                        collectionDay: yup.number().moreThan(0).required(),
                        collectionRawData: yup.string().matches(/^[0-9.\s]+$/, "Must contains only numbers and white spaces").required(),
                        additionalNote: yup.string(),
                    })}
                    onSubmit={this.onSubmit.bind(this)}
                    submissionStatus={this.state.submitStatus}
                    autofillOwnerByLogInId={false}
                    view={this.props.view}
                />
                {
                    this.state.initialValues.plateId !== "" &&
                    this.state.submitStatus.status === ApiCallStatus.Completed &&
                    <GenGenPlateCalculatedResult
                        collectionRound={this.state.initialValues.collectionRound}
                        refreshToken={this.state.calculationResultRefreshToken}
                        view={this.props.view}
                        plateId={this.state.initialValues.plateId}/>
                }
            </Card.Body>
        </Card>;
    }

}