import {Storage} from "aws-amplify";
import React, {ReactElement} from "react";
import {Button, Spinner} from "react-bootstrap";
import {CloudDownload} from "react-bootstrap-icons";
import {ApiCallStatus, BUCKET_NAME} from "./Const";
import {IconWarning} from "@aws-amplify/ui-react/internal";

interface DownloadButtonProps {
    s3Bucket: string
    s3Keys: string[]
    buttonSize?: "sm" | "lg" | undefined
    variant: "primary" | "success" | "danger"
    text?: string
    icon?: ReactElement
    filename?: string[]
    className?: string
    setErrorMessage?(msg: string): void
}

interface DownloadButtonState {
    downloadStatus: ApiCallStatus
    btnContent: any
    downloadErrMsg: string
}

export class DownloadButton extends React.Component<DownloadButtonProps, DownloadButtonState> {
    constructor(props: DownloadButtonProps) {
        super(props);

        this.state = {
            downloadStatus: ApiCallStatus.Completed,
            btnContent: this.resetBtnContent(),
            downloadErrMsg: ""
        }
    }

    resetBtnContent(): any {
        let btnContent: any = <CloudDownload/>
        if (this.props.text) {
            btnContent = this.props.text
        } else if (this.props.icon) {
            btnContent = this.props.icon
        }
        return btnContent
    }

    btnContentLoading(): any {
        let btnContent: any = <Spinner animation="border" size={"sm"}/>
        if (this.props.text) {
            btnContent = "Downloading ..."
        }
        return btnContent
    }

    downloadFiles() {
        this.setState({
            downloadStatus: ApiCallStatus.Loading,
            btnContent: this.btnContentLoading()
        }, () => {
            for (let s3Key of this.props.s3Keys) {
                if (s3Key === "") {
                    if (this.props.setErrorMessage !== undefined) {
                       this.setState({
                               downloadStatus: ApiCallStatus.Error,
                               btnContent: this.resetBtnContent()
                           },
                           () => {
                               // @ts-ignore
                               this.props.setErrorMessage("At least one report cannot be downloaded, because it's progress is not completed")
                           }
                       )
                    } else {
                        this.setState({
                            downloadStatus: ApiCallStatus.Error,
                            btnContent: this.resetBtnContent(),
                            downloadErrMsg: "At least one report cannot be downloaded, because it's progress is not completed"
                        })
                    }
                    return
                }
            }

            Promise.all(
                this.props.s3Keys.map(
                    (s3Key, index) => this.downloadSingleFile(s3Key, this.props.filename ? this.props.filename[index] : "")
                )
            ).then((results) => {
                if (this.props.setErrorMessage !== undefined) {
                    this.setState({
                            downloadStatus: ApiCallStatus.Error,
                            btnContent: this.resetBtnContent()
                        },
                        () => {
                            // @ts-ignore
                            this.props.setErrorMessage("")
                        }
                    )
                } else {
                    this.setState({
                        downloadStatus: ApiCallStatus.Completed,
                        btnContent: this.resetBtnContent(),
                        downloadErrMsg: ""
                    })
                }
            }).catch((err) => {
                console.log("Download error ", err)
                if (this.props.setErrorMessage !== undefined) {
                    this.setState({
                            downloadStatus: ApiCallStatus.Error,
                            btnContent: this.resetBtnContent()
                        },
                        () => {
                            // @ts-ignore
                            this.props.setErrorMessage(err)
                        }
                    )
                } else {
                    // Break the loop if there is one file we cannot download -- TODO - hmm, will it exit other parallel download?
                    this.setState({
                        downloadStatus: ApiCallStatus.Error,
                        btnContent: this.resetBtnContent(),
                        downloadErrMsg: err
                    })
                }
            })
        })
    }

    async downloadSingleFile(s3Key: string, fileName: string): Promise<void> {
        // First, remove public prefix from s3Key to make it works with Amplify Storage
        console.log("Download file " + s3Key)
        if (s3Key.startsWith("public/")) {
            s3Key = s3Key.replace("public/", "")
        }
        const result = await Storage.get(s3Key, {
            bucket: BUCKET_NAME,
            download: true
        }).catch(error => {
            console.log(error)
            throw error
        });
        console.log("Success");
        if (!fileName) {
            let s3FileName = s3Key.split("/").pop();
            fileName = s3FileName === undefined ? "" : s3FileName;
        }
        this.downloadFileAsBlob(result.Body, fileName);
    }

    downloadFileAsBlob(blob: any, filename: string) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    render() {
        if (this.props.s3Keys.length > 0) {
            return <>
                <Button
                    variant={this.props.variant}
                    size={this.props.buttonSize}
                    onClick={this.downloadFiles.bind(this)}
                    className={this.props.className}
                >
                    {this.state.btnContent ? this.state.btnContent : <CloudDownload />}
                </Button>
                {this.state.downloadErrMsg && <p className={"text-danger mb-0"}><span className={"me-2"}><IconWarning /></span>{this.state.downloadErrMsg}</p>}
            </>;
        }
        return <></>
    }
}