import React from "react";
import {Button, Col, FloatingLabel, Form, Row, Spinner} from "react-bootstrap";
import {API, Logger, Storage} from "aws-amplify";
import { v4 as uuidv4 } from 'uuid';
import {USER_NAME} from "../Auth/LoginUser";
import {sangerQcApiConsts} from "./Const";
import {errorMessage} from "../Alerts/Const";
import {bucketName} from "../../Const";

interface SangerQCInputFormState {
    description: string,
    userName: string,
    slNumber: string,
    fileUploaded: any,
    s3URL: string,
    stage: string
    formDisplay: {
        disabled: boolean
    }
    slConfigFilesS3Keys: string[]
    selectedSLConfigFileS3Key: string
}

interface SangerQCInputFormProps {
    refreshHistoryTable(): void
    showErrorAlert(message: string): void
}

interface SangerQCPipelineRequest {
    uuid: string,
    description: string,
    userName: string,
    slNumber: string,
    s3URL: string,
    stage: string,
    selectedSLConfigFileS3Key: string
}

const logger = new Logger("SangerQCInputForm")

export class SangerQCInputForm extends React.Component<SangerQCInputFormProps, SangerQCInputFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            description: "",
            userName: "",
            slNumber: "",
            fileUploaded: "",
            s3URL: "",
            stage: "",
            formDisplay: {
                disabled: false
            },
            slConfigFilesS3Keys: [],
            selectedSLConfigFileS3Key: ""
        }
    }

    componentDidMount() {
        logger.info("SangerQCInputForm - componentDidMount - current env is " + process.env.NODE_ENV)
        Storage
            .list("sl-config/", {
                level: "public"
            }).then(response => {
                let slConfigFilesS3Keys: string[] = []
                // Skip the first element which is folder name
                for (let i=1; i < response.length; i++) {
                    slConfigFilesS3Keys.push("public/" + response[i].key)
                }
                this.setState({
                    slConfigFilesS3Keys: slConfigFilesS3Keys
                })
            }).catch(err => {
                logger.info(err)
                this.props.showErrorAlert(errorMessage(err))
        })
    }

    setFileUploaded(e: any) {
        let fileExtension: string = e.target.files[0].name.split(".").pop()

        // Check for file type
        if (fileExtension !== "zip" && fileExtension !== "gzip") {
            // Notify error
            logger.error("Error: cannot accept file other than zip/gzip")
            e.target.value = null
            this.props.showErrorAlert(errorMessage("Error: cannot accept file other than zip/gzip"))
            return
        }

        this.setState({
            fileUploaded: e.target.files[0]
        })
    }

    setDescription(e: any) {
        this.setState({
            description: e.target.value.toString()
        })
    }

    setSLNumber(e: any) {
        let slNumberExtracted = this.extractSLNumber(e.target.value.toString())
        logger.info("slNumberExtracted = " + slNumberExtracted)
        this.setState({
            selectedSLConfigFileS3Key: e.target.value.toString(),
            slNumber: slNumberExtracted
        })
    }

    setStage(e: any) {
        this.setState({
            stage: e.target.value.toString()
        })
    }

    submitPipeline(e: any) {
        e.preventDefault()
        let reportID: string = uuidv4()

        // Block the submit button, with loading animation until the form submission is completed
        this.setState({
            formDisplay: {disabled: true}
        })

        // Upload file to S3 first
        Storage.put(reportID+".zip", this.state.fileUploaded, {
            contentType: "application/zip"
        }).then(response => {
            // Then set state
            this.setState({
                s3URL: "https://s3.console.aws.amazon.com/s3/object/" + bucketName + "?region=us-east-1&prefix=public/" + reportID
            })
        }).then(() => {
            // Then submit form
            let requestBody: SangerQCPipelineRequest = {
                uuid: reportID,
                description: this.state.description,
                userName: USER_NAME,
                slNumber: this.state.slNumber,
                selectedSLConfigFileS3Key: this.state.selectedSLConfigFileS3Key,
                s3URL: this.state.s3URL,
                stage: this.state.stage,
            }

            API.post(sangerQcApiConsts.API_NAME, sangerQcApiConsts.Path.BASE, {body: requestBody}).then(response => {
                logger.info("Response from API - " + response)

                // Clear form data
                e.target["sanger-qc-submit-form-file-upload"].value = ""
                this.setState({
                    description: "",
                    slNumber: "",
                    fileUploaded: "",
                    s3URL: "",
                    stage: "",
                    selectedSLConfigFileS3Key: "",
                })

                // Trigger history table refresh
                this.props.refreshHistoryTable()
            }).catch(err => {
                // Don't clear input, notification of error
                logger.error("Error from API " + err)
                this.props.showErrorAlert(errorMessage(err))
            })
        }).catch(err => {
            // Don't clear input, notification of error
            logger.error(err)
            this.props.showErrorAlert(errorMessage(err))
        }).finally(() => {
            this.setState({
                formDisplay: {
                    disabled: false
                }
            })
        })
    }

    // Extract 4 from sl-config/SL4.config.txt
    extractSLNumber(slConfigFileS3Key: string): string {
        return slConfigFileS3Key.split("/")[2].split(".")[0].charAt(2)
    }

    render() {
        // Submit button should show loading animation, and be disabled, until submission is successful.
        let submitButton = (
            <Button id="sanger-qc-input-form-submit-button" variant="primary" disabled>
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-1"
                />
                Submitting ...
            </Button>)
        ;
        if (!this.state.formDisplay.disabled) {
            submitButton = (<Button id="sanger-qc-input-form-submit-button" variant="primary" type="submit">Submit</Button>)
        }

        return (
            <>
                <h3>Submit a Sanger QC job</h3>
                <Form onSubmit={this.submitPipeline.bind(this)}>
                    <Form.Group className="mb-3">
                        <FloatingLabel label="Short description">
                            <Form.Control type="text" placeholder="Short description"
                                          aria-label="Short description" aria-describedby="basic-addon1"
                                          id="sanger-qc-submit-form-description" required
                                          value={this.state.description}
                                          disabled={this.state.formDisplay.disabled}
                                          onChange={this.setDescription.bind(this)}
                            />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col lg="6">
                            <Form.Select id="sanger-qc-submit-form-sl-number"
                                          value={this.state.selectedSLConfigFileS3Key}
                                          disabled={this.state.formDisplay.disabled}
                                          onChange={this.setSLNumber.bind(this)}
                                          required={true}>
                                <option value="">Select SL</option>
                                { this.state.slConfigFilesS3Keys.map(key => (<option key={key} value={key}>{this.extractSLNumber(key)}</option>))}
                            </Form.Select>
                        </Col>
                        <Col lg={"6"}>
                            <Form.Select id="sanger-qc-submit-form-stage"
                                         value={this.state.stage}
                                         disabled={this.state.formDisplay.disabled}
                                         onChange={this.setStage.bind(this)}
                                         required={true}
                            >
                                <option value="">Select library</option>
                                <option value="Intermediate library">Intermediate library</option>
                                <option value="Final library">Final library</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Zip all .ab1 files locally and upload it here</Form.Label>
                        <Form.Control
                            id="sanger-qc-submit-form-file-upload"
                            type="file"
                            data-show-upload="false"
                            data-show-caption="true"
                            disabled={this.state.formDisplay.disabled}
                            onChange={this.setFileUploaded.bind(this)}
                            required
                        />
                    </Form.Group>
                    {submitButton}
                </Form>
            </>
        );
    }
}